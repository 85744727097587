export function formatDate(isoDateString) {
  const createdAt = isoDateString;
  const date = new Date(createdAt);
  const options = { year: "numeric", month: "short", day: "numeric" };
  const formattedDate = date.toLocaleDateString("en-US", options);

  return formattedDate;
}

export const formatDateTime = (dateString) => {
  const date = new Date(dateString);
  const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
  const formattedDate = date.toLocaleDateString('en-US', options);

  const month = formattedDate.split(' ')[0];
  const day = parseInt(formattedDate.split(' ')[1]).toString();
  const year = formattedDate.split(' ')[2];

  const hour = date.getHours();
  const minute = date.getMinutes();
  const period = hour >= 12 ? 'PM' : 'AM'; // Determine AM or PM
  const formattedHour = (hour % 12 === 0 ? 12 : hour % 12).toString();

  const formattedTime = `${formattedHour}:${minute.toString().padStart(2, '0')}${period}`;

  return (
    <span style={{ display: 'flex', gap: '5px' }}>
      {month} {day}, {year}
      <div className="divider">|</div>
      {formattedTime}
    </span>
  );
};

