import { useState } from "react";
import Topbar from "../components/topbar/Topbar";
import { Link } from "react-router-dom";
import Button from "@/component/buttons/Button";
import UpdatesTable from "./updateTable/UpdateTable";

const Updates = () => {
  // eslint-disable-next-line
  const [updates, setUpdates] = useState([
    {
      title: "Improvements to User Interface",
      status: "publish",
      createdDate: "2024-04-20",
      releaseDate: "2024-04-25",
    },
    {
      title: "Bug Fixes and Performance Enhancements",
      status: "unpublished",
      createdDate: "2024-04-21",
      releaseDate: "2024-04-26",
    },
    {
      title: "New Feature: Dark Mode",
      status: "publish",
      createdDate: "2024-04-22",
      releaseDate: "2024-04-27",
    },
    {
      title: "Security Updates and Patches",
      status: "published",
      createdDate: "2024-04-23",
      releaseDate: "2024-04-28",
    },
    {
      title: "Improved Performance for Large Datasets",
      status: "unpublished",
      createdDate: "2024-04-24",
      releaseDate: "2024-04-29",
    },
    {
      title: "Enhanced Accessibility Features",
      status: "published",
      createdDate: "2024-04-25",
      releaseDate: "2024-04-30",
    },
    {
      title: "Integration with Third-Party APIs",
      status: "published",
      createdDate: "2024-04-26",
      releaseDate: "2024-05-01",
    },
    {
      title: "Improved Documentation and Tutorials",
      status: "unpublished",
      createdDate: "2024-04-27",
      releaseDate: "2024-05-02",
    },
  ]);
  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <Topbar name={"Updates"} />
        </div>
        <div className="col-12">
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between">
                <div className="form d-flex align-items-end   gap-2 col-lg-12">
                  <div
                    className="input-search  d-flex py-2"
                    style={{ flex: "1" }}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for users"
                      className="input_text"
                    />
                  </div>

                  <div className="d-flex gap-3">
                    <div>
                      <Button text="Sort" primaryNull={true} small={true} />
                    </div>
                    <div>
                      <Link to={'/admin/create-update'}>
                      <Button
                        text="Create Update"
                        primaryNull={false}
                        small={true}
                      /></Link>
                      
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <UpdatesTable rows={updates} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Updates;
