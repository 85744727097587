import { Box, CircularProgress, Grid, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import service from "../../services";
import api from "../../services/api";
import Button from "../../component/buttons/Button";
import RestrictModal from "./Modal/RestrictModal/RestrictModal";
import { useNavigate } from "react-router-dom";

const ChatWindow = ({
  chat,
  onBack,
  socket,
  loading,
  userType,
  userTypeDetails,
}) => {
  const navigate = useNavigate();

  const [message, setMessage] = useState("");
  const [sender, setSender] = useState("");
  const [senderId, setSenderId] = useState("");

  const [messages, setMessages] = useState([]);

  const [isRestricting, setIsRestricting] = useState(false);

  const [isEnding, setIsEnding] = useState(false);
  const [isEnded, setIsEnded] = useState(false);

  const [isEndingReason, setIsEndingReason] = useState(false);
  const [declineReason, setDeclineReason] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  const [itemsPerPage, setItemsPerPage] = useState(100);
  const [olderMessagesLoading, setOldMessagesLoading] = useState(false)

  const [showLoadMoreButton, setShowLoadMoreButton] = useState(false);
  const messagesEndRef = useRef(null);
  const chatWindowRef = useRef(null);

  useEffect(() => {
    setSender('ADMIN');
    // setSender(service.getTokenData()?.userRole);
    setSenderId(service.getUserID());
  }, []);

  useEffect(() => {
		const handleMessageReceive = (newMessage) => {
			setMessages((prevMessages) => [...prevMessages, newMessage]);
			// scrollToBottom();
			const readData = {
				reader: sender,
				messageId: newMessage._id
			}
			if(chat){
				socket.emit('read-message', readData)
			}
		};
		socket.on('receive-message', handleMessageReceive);
		// Cleanup to remove the listener when the component unmounts or socket changes
		return () => {
			socket.off('receive-message', handleMessageReceive);
		};
	}, [chat, sender, socket]);

  const sendAMessage = async () => {
    if (message !== "") {
      const messageData = {
        conversationId: chat?._id,
        text: message,
        sender: sender,
        senderId: senderId,
      };
      await socket.emit("send-message", messageData, (data) => {
        if (data === true) {
          setMessages((prevMessages) => [
            ...prevMessages,
            { ...messageData, sender: sender },
          ]);
          setMessage("");
        }
      });
    }
  };

  useEffect(() => {
    if (chat?.isEnded) {
      setIsEnded(true);
    }
  }, [chat?.isEnded]);

  useEffect(() => {
    setIsLoading(true);
    if (chat?._id !== undefined) {
      api
        .getMessages(chat?._id,sender)
        .then((res) => {
          const reversedArray = res.data.reverse()
          setMessages(reversedArray);
        })
        .catch(console.error)
        .finally(() => {
          setIsLoading(false);
          scrollToBottom();
        });
    }
  }, [chat?._id, sender]);

  const fetchOlderMessages = async () => {
		setOldMessagesLoading(true)
		setItemsPerPage((prev)=>prev + itemsPerPage)
	};

  const handleScroll = () => {
    if (chatWindowRef.current.scrollTop === 0 && messages.length >= 100) {
      setShowLoadMoreButton(true);
    } else {
      setShowLoadMoreButton(false);
    }
  };

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const renderName = (chat) => {
    if (userType === service.RECRUITER) {
      return (
        <h5 style={{ color: "#D5D5D6" }}>
          {service.truncateText(
            `${chat.talent?.first_name} ${chat.talent?.last_name}`,
            16
          )}
        </h5>
      );
    } else {
      return (
        <h5 style={{ color: "#D5D5D6" }}>
          {service.truncateText(
            `${chat.recruiter?.first_name} ${chat.recruiter?.last_name}`,
            16
          )}
        </h5>
      );
    }
  };

  const handleEndConversation = async () => {
    if (declineReason !== "") {
      const data = {
        conversationId: chat?._id,
        reason: declineReason,
      };
      await socket.emit("end-conversation", data, (data) => {
        if (data === true) {
          setIsEndingReason(false);
          setIsEnded(true);
        }
      });
    }
  };

  const handleRestrict = (id) => {
    console.log(chat);
  };

  const handleResumeConversation = async () => {
    await socket.emit("resume-conversation", chat?._id, (data) => {
      if (data === true) {
        setIsEnded(false);
      }
    });
  };

  const handleHideOrReveal = async (message) => {
    // Optimistically update the UI
    setMessages((prevMessages) =>
      prevMessages.map((msg) =>
        msg._id === message._id ? { ...msg, isHidden: !msg.isHidden } : msg
      )
    );
  
    const data = {
      conversationId: chat?._id,
      messageId: message._id,
    };
    
    const type = message.isHidden ? "reveal-message" : "hide-message";
    
    // Emit the event to the server
    socket.emit(type, data, (response) => {
      if (response !== true) {
        // If the server indicates failure, revert the change
        setMessages((prevMessages) =>
          prevMessages.map((msg) =>
            msg._id === message._id ? { ...msg, isHidden: message.isHidden } : msg
          )
        );
      } else {
        console.log('Message state updated successfully');
      }
    });
  };
  

  if (!chat) {
    return null
  }

  return (
    <section
      style={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
      }}
    >
      <div className="d-lg-none p-2">
        <button className="btn btn-link" onClick={onBack}>
          &larr; Back
        </button>
      </div>

      <div
        style={{
          backgroundColor: "#2E2E30",
          padding: "15px 20px",
          borderRadius: "10px 10px 0 0",
          position: "relative",
        }}
      >
        <div style={{ display: "flex", gap: "20px" }}>{renderName(chat)}</div>
      </div>

      <div
        className="flex-grow-1 overflow-auto p-3 shadow mb-1"
        style={{
          minHeight: "500px",
          height: "100%",
          backgroundColor: "white",
          position: "relative",
        }}
      >
        <div
          style={{
            display: "flex",
            gap: "5px",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            marginBottom: "20px",
          }}
        >
          <img src="/img/svg/ensure.svg" alt="" style={{ width: "40px" }} />
          <p>
            Ensure you don’t share personal information like email address,
            phone numbers and other sensitive information during your
            conversations
          </p>
        </div>
        {isEnded && (
          <div
            style={{
              backdropFilter: "blur(5px)",
              position: "absolute",
              top: "50%",
              left: "50%",
              width: "100%",
              height: "100%",
              zIndex: 10000,
              transform: "translate(-50%, -50%)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Grid
              open={isEnded}
              container
              justifyContent="center"
              alignItems="center"
              sx={{
                width: "auto",
                height: "200px",
                maxWidth: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
                zIndex: 1000,
              }}
            >
              <Grid item xs={12} textAlign="center">
                <Typography variant="h6" component="h2" gutterBottom>
                  Conversation Ended
                </Typography>
              </Grid>
              <p>{isEndingReason}</p>
              <Grid item container justifyContent="center" spacing={2}>
                <Button
                  text="Resume Conversation"
                  small
                  onClick={handleResumeConversation}
                />
              </Grid>
            </Grid>
          </div>
        )}
        {showLoadMoreButton && (
          <button onClick={fetchOlderMessages} disabled={olderMessagesLoading}>
            {olderMessagesLoading ? "Loading..." : "Load Older Messages"}
          </button>
        )}
        <div
          ref={chatWindowRef}
          onScroll={handleScroll}
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            height: "400px",
            overflowY: "auto",
            position: "relative",
          }}
        >
          {isLoading ? (
            <Box display="flex" justifyContent={"center"} width={"100%"}>
              <CircularProgress sx={{ color: "#FFB700" }} />
            </Box>
          ) : (
            messages.map((message, index) => (
              <div
                key={index}
                style={{
                  display: "flex",
                  justifyContent:
                    message.sender.toLowerCase() === userType
                      ? "flex-end"
                      : "flex-start",
                  width: "100%",
                }}
              >
                <article style={{ width: "auto", maxWidth: "60%" }}>
                  <div
                    style={{
                      width: "auto",
                      padding: "10px",
                      borderRadius: "15px",
                      backgroundColor:
                        message.sender.toLowerCase() === userType
                          ? "#F5DDCC"
                          : "#FFF1CC",
                      boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                      wordBreak: "break-word",
                      position: "relative",
                    }}
                  >
                    <div
                      // style={{
                      //   filter: message.isHidden ? "blur(5px)" : "none",
                      // }}
                    >
                      {message.text}
                    </div>
                  </div>
                  <div
                    className="d-flex gap-1"
                    style={{
                      justifyContent:
                        message.sender.toLowerCase() === userType
                          ? "flex-end"
                          : "flex-start",
                      fontSize: "12px",
                      color: "#CC5600",
                    }}
                  >
                    <button
                      onClick={() => handleHideOrReveal(message)}
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src="/img/svg/danger.svg"
                        alt=""
                        style={{ width: "12px", height: "12px" }}
                      />
                      {message.isHidden === true ? "Reveal" : "Hide"}
                    </button>
                    <button onClick={handleRestrict}>
                      Restrict Conversation
                    </button>
                  </div>
                </article>

                {isRestricting && (
                  <div
                    open={isRestricting}
                    onClose={() => setIsRestricting(false)}
                  >
                    <Grid
                      container
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: "80%",
                        maxWidth: 400,
                        bgcolor: "background.paper",
                        boxShadow: 24,
                        p: 4,
                        borderRadius: 2,
                      }}
                    >
                      <Grid item xs={12} textAlign="center">
                        <Typography variant="h6" component="h2" gutterBottom>
                          Warning
                        </Typography>
                      </Grid>
                      <Grid item xs={12} textAlign="center" mb={2}>
                        <img
                          src="/img/Group 282.png"
                          alt=""
                          style={{ width: "auto", height: 120 }}
                        />
                      </Grid>
                      <Grid item xs={12} textAlign="center" mb={4}>
                        <Typography variant="body1">
                          Are you certain you want to restrict this
                          conversation?
                        </Typography>
                      </Grid>
                      <Grid item container justifyContent="center" spacing={2}>
                        <Grid item>
                          <Button
                            text="No,Discard"
                            primaryNull
                            onClick={() => {
                              setIsRestricting(false);
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <Button
                            text="Yes, Go ahead"
                            onClick={() => handleRestrict(message.id)}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </div>
                )}
              </div>
            ))
          )}
          {/* <div ref={messagesEndRef} /> */}
        </div>
      </div>

      <div
        className="d-flex gap-1"
        style={{
          marginTop: "auto",
          width: "100%",
          alignItems: "center",
          backgroundColor: "white",
          padding: "10px",
        }}
      >
        <article
          className=""
          style={{
            width: "80%",
            backgroundColor: "#EBEBEB",
            borderRadius: "1000px",
            padding: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          <input
            type="text"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            placeholder="Keep the conversation going!"
            style={{
              backgroundColor: "transparent",
              border: "none",
              outline: "none",
              flex: 1,
              padding: "5px",
            }}
          />
        </article>
        <article
          className="d-flex gap-1"
          style={{ width: "20%", alignItems: "center" }}
        >
          <div
            style={{
              border: "1px solid #CC5600",
              padding: "7px",
              borderRadius: "10px",
              cursor: "pointer",
            }}
            onClick={sendAMessage}
          >
            <img src="/img/svg/send.svg" alt="" style={{ width: "30px" }} />
          </div>
        </article>
      </div>

      <div className="d-flex gap-2">
        {!isEnded && (
          <Button
            text="End Conversation"
            small
            primaryNull
            onClick={() => setIsEnding(true)}
          />
        )}
        <Button
          text="View Recruiter"
          small
          primaryNull
          onClick={() => navigate(`/admin/view-profile/${chat?.recruiter?._id}`)}
        />
        <Button
          text="View Talent"
          small
          primaryNull
          onClick={() => navigate(`/admin/view-profile/${chat?.talent?._id}`)}
        />
      </div>

      {isEnding && (
        <Modal
          open={isEnding}
          onClose={() => setIsEnding(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6" component="h2" gutterBottom>
                Warning
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" mb={2}>
              <img
                src="/img/Group 282.png"
                alt=""
                style={{ width: "auto", height: 120 }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center" mb={4}>
              <Typography variant="body1">
                Are you certain you want to end??
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" spacing={2}>
              <Grid item>
                <Button
                  text="No, Discard"
                  primaryNull
                  small
                  onClick={() => {
                    setIsEnding(false);
                  }}
                />
              </Grid>
              <Grid item>
                <Button
                  small
                  text="Yes, Go ahead"
                  onClick={() => {
                    setIsEnding(false);
                    setIsEndingReason(true);
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
      )}

      {isEndingReason && (
        <RestrictModal
          open={isEndingReason}
          handleClose={() => setIsEndingReason(false)}
          textValue={declineReason}
          handleEnding={handleEndConversation}
          handleChange={(e) => setDeclineReason(e.target.value)}
        />
      )}
    </section>
  );
};

export default ChatWindow;
