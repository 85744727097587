import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import Button from "@/component/buttons/Button";
import { useNavigate } from "react-router-dom";
// import MenuItem from "@mui/material/MenuItem";
// import FormControl from "@mui/material/FormControl";
// import Select from "@mui/material/Select";
import { formatDate } from "../../../../../component/formatter";


function Row({ row }) {
  // const [age, setAge] = useState("pending");
  const navigate = useNavigate();

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell className="custom-cell">{row.job.title}</TableCell>
        <TableCell className="custom-cell">{row.recruiter ? row?.recruiter?.first_name + " " + row?.recruiter?.last_name : 'Deleted Recruiter'}</TableCell>
        <TableCell className="custom-cell">{row.applicant_first_name + " " + row.applicant_last_name}</TableCell>
        <TableCell className="custom-cell">{formatDate(row.createdAt)}</TableCell>
        {/* <TableCell className="custom-cell">
          <FormControl>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={`muiSelect ${age}`}
            >
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"declined"}>Declined</MenuItem>
            </Select>
          </FormControl>
        </TableCell> */}
        <TableCell className="custom-cell">
          <div className="d-flex gap-3 col-lg-3">
            <div>
              <Button text="View" primaryNull={true} small={true} onClick={() => navigate(`/admin/view-profile/${row.talent}`)} />
            </div>
            {/* <div>
              <div className="move_dots">
                <span className="mb-1">...</span>
              </div>
            </div> */}
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function ApplicantsTable({ jobs, currentPage, itemsPerPage, loading, dateSortingOrder, setCurrentPage }) {

  // const totalNumberOfPages = Math.ceil(jobs.length / itemsPerPage)
  // const goToPreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1)
  //   }
  // }

  // const goToNextPage = () => {
  //   if (currentPage < totalNumberOfPages) {
  //     setCurrentPage(currentPage + 1)
  //   }
  // }
  return (
    <TableContainer>
      <Table aria-label="JobListing table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Position</TableCell>
            <TableCell>Recruiter</TableCell>
            <TableCell>Applicant</TableCell>
            <TableCell>Date Created</TableCell>
            {/* <TableCell>Status</TableCell> */}
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job, index) => {
            return <Row row={job} key={index} />;
          })}
        </TableBody>
      </Table>
      {/* {loading ? <Loading /> : <Pagination goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalNumberOfPages={totalNumberOfPages} />} */}
    </TableContainer>
  );
}
