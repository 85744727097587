import React from "react";
import TextField from "@mui/material/TextField";
const SelectField = ({ id, label, defaultValue, options, onChange, helperText, title, variant = "standard" }) => {
  return (
    <div>
      <label className="input_label">{title}</label>

      <TextField
        variant={variant}
        id={id}
        select
        label={label}
        defaultValue={defaultValue}
        SelectProps={{
          native: true,
        }}
        helperText={helperText}
        fullWidth
        onChange={onChange}
      >
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </TextField>
    </div>
  );
};

export default SelectField;
