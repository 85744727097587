import "./css/Profile.style.sass";
import BreadCrumb from "../../component/bread-crumb/BreadCrumb";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../services/api";
import service from "../../services";
import ProfileRecruiterTable from './ProfileRecruiterTable/ProfileRecruiterTable'
import Loading from "../dashboardreview/components/loading/loading";


function ProfileRecruiter() {
  const { id } = useParams();

  const [user, setUser] = useState({});
  const [jobsPosted, setJobsPosted] = useState([])
  const [applicationMade, setApplicationmade] = useState('')
  const [isLoading, setIsLoading] = useState(true);
  const [loadingImage, setLoadingImage] = useState(true);

  const breadCrumbProp = [
    {
      title: "Users",
      link: "",
      previous: true,
    },
    {
      title: "Profile",
      link: "",
    },
  ];

  useEffect(() => {
    setIsLoading(true);
    setLoadingImage(true);
    api
      .getRecuriter(id)
      .then((res) => {
        setUser(res.data.recruiter);
        setApplicationmade(res.data?.totalApplications)
      })
      .catch((err) => console.log(err))
      .finally(() => {
        setIsLoading(false);
        setLoadingImage(false);
      });
    api.getJobs({ recruiterId: id }).then((res) => setJobsPosted(res.data?.jobs)).catch(res => console.log(res))
  }, [id]);

  return (
    <div
      id="Recruiter_View_Talent_Profile_Main_Container"
      className="col-lg-10 col-12"
    >
      <div>
        <div className="container-01 py-4">
          <div className="row ">
            <div className="first-col col-12 mb-5 mb-lg-0">
              <div className="outline-wrap" style={{ marginBottom: "40px" }}>
                <BreadCrumb context={breadCrumbProp} />

                <div
                  className="section  py-4 px-3"
                  style={{
                    border: "4px solid #000000",
                    borderRadius: "10px",
                  }}
                >
                  {!isLoading && (
                    <div
                      className={`section-a d-lg-flex align-items-center justify-content-between flex-column`}
                    >
                      <div className="row justify-content-between w-100">
                        <div className="col-lg-4">
                          <div className="job_number">
                            <h4>Overview</h4>
                            <h5 className="my-4">
                              {jobsPosted?.length || 0} <span>Job Posted</span>
                            </h5>
                            <hr />
                            <h5 className="my-4">
                              {applicationMade} <span>Application made</span>
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-7 side_profile">
                          <div className="d-flex   flex-lg-row align-items-center gap-3  mb-lg-0 w-100">
                            <div className=" recruiter_img_con">
                              <div className="user-img ">
                                {!loadingImage && (
                                  <img src={user?.company_logo?.href_link} alt="" />
                                )}

                                {loadingImage && <div className={"animated-bg"}></div>}
                              </div>
                            </div>

                            <div className="user-context">
                              <div>
                                <h1>
                                  {user?.first_name && service.titleCase(user?.first_name)}{" "}
                                  {user?.last_name && service.titleCase(user?.last_name)}
                                </h1>
                                <div className="d-flex mt-2 justify-content-between gap-3">
                                  <p className="mb-0">
                                    {user?.email}
                                  </p>
                                  <p>{user?.phone_number}</p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="company_details d-flex justify-content-between">
                            <div>
                              <p className="details_title">Company Name</p>

                              <h2>{user?.company_name}</h2>
                            </div>
                            <div className="d-flex gap-3">
                              <div>
                                <p className="details_title">Position in Company</p>

                                <h2>{user.recruiter_position}</h2>
                              </div>
                              <div>
                                <p className="details_title">Staff capacity</p>

                                <h2>{user?.staff_capacity}</h2>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="con-animated-bg">
                    {isLoading &&
                      [1, 1, 1].map((x, y) => (
                        <div className={"animated-bg"} key={y}></div>
                      ))}
                  </div>
                </div>

                <div
                  className="recruiter_body_con"
                  style={{
                    border: "4px solid #000",
                    borderRadius: "10px",
                    marginTop: "3rem",
                    padding: "10px",
                  }}
                >
                  <h2>Posted Opportunities</h2>
                  <div className="my-5">
                    {
                      isLoading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                        >
                          <Loading />
                        </div>
                      ) : (<ProfileRecruiterTable jobs={jobsPosted} />)
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileRecruiter;
