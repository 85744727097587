import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import IconButton from "@mui/material/IconButton";
import Button from "@/component/buttons/Button";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";

function Row({ row }) {
  // eslint-disable-next-line
  // const [age, setAge] = useState("pending");

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "200px",
            whiteSpace: "nowrap",
          }}
        >
          {row.title}
        </TableCell>
        <TableCell className="custom-cell">
          <span
            className="tag"
            style={{
              background: row.status === "unpublished" ? "#FFB700" : "#0FAA4E",
              color: row.status === "unpublished" ? "#000000" : "#FFFFFF",
            }}
          >
            {row.status}
          </span>{" "}
        </TableCell>
        <TableCell className="custom-cell">{row.createdDate}</TableCell>

        <TableCell className="custom-cell">{row.releaseDate}</TableCell>

        <TableCell className="custom-cell">
          <div className="d-flex gap-2  ">
            <div>
              <Button text="Profile" primaryNull={true} small={true} />
            </div>
            <IconButton
              sx={{
                border: "1px solid #FFB700",
                color: "#FFB700",
                borderRadius: "10px",
                width: "34px",
                height: "34px",
              }}
            >
              <EditOutlined />
            </IconButton>
            <IconButton
              sx={{
                border: "1px solid #E23636",
                color: "#E23636",
                borderRadius: "10px",
                width: "34px",
                height: "34px",
              }}
            >
              <DeleteOutline />
            </IconButton>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function UpdatesTable({ rows }) {
  return (
    <TableContainer>
      <Table aria-label="JobListing table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Titles</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Date Released</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return <Row row={row} key={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
