import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import Button from "@/component/buttons/Button";
import { formatDate } from "../../../../component/formatter";
import { useNavigate } from "react-router-dom";

function Row({ row, verify, recruiter }) {
  // const [age, setAge] = useState("pending");
  const navigate = useNavigate();

  // const handleChange = (event) => {
  //   setAge(event.target.value);
  // };
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            maxWidth: "100px",
          }}
        >
          {''}
        </TableCell>
        <TableCell className="custom-cell">{row.first_name + " " + row.last_name}</TableCell>
        <TableCell className="custom-cell"><p style={{ backgroundColor: '#FFB700', display: 'inline-block', padding: '5px 10px', textTransform: 'capitalize', borderRadius: '10px', textAlign: 'center' }}>{row.type}</p></TableCell>
        <TableCell className="custom-cell">{formatDate(row.createdAt)}</TableCell>
        <TableCell className="custom-cell">
          <div className="d-flex gap-3 col-lg-3">
            <div>
              <Button
                text="Profile"
                primaryNull={true}
                small={true}
                onClick={() =>
                  navigate(
                    row.type === "talent"
                      ? `/admin/view-profile/${row.id}`
                      : `/admin/view-recriuter/${row.id}`
                  )
                }
              />
            </div>
            <div>
              {row.isProfileApproved === false ? <Button
                text="Verify"
                primaryNull={true}
                small={true}
                greenBtn={true}
                onClick={() =>
                  row.type === 'talent' ?
                    verify(row?.id) : recruiter(row?.id)
                }
              /> :
                <div style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '5px',
                  textAlign: 'center',
                  border: '1px solid green',
                  width: '100%',
                  padding: '6px 8px',
                  borderRadius: '5px'
                }}>
                  <img src="/img/svg/verified.svg" alt="" style={{ width: '24px', height: '24px' }} />
                  <p style={{ margin: '0' }}>Verified</p>
                </div>
              }
            </div>
            <div>
              <div className="move_dots">
                <span className="mb-1">...</span>
              </div>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function UsersListingTable({ jobs, currentPage, itemsPerPage, loading, dateSortingOrder, setCurrentPage, verifyTalent, verifyRecruiter }) {

  // const totalNumberOfPages = Math.ceil(jobs.length / itemsPerPage)
  // const goToPreviousPage = () => {
  //   if (currentPage > 1) {
  //     setCurrentPage(currentPage - 1)
  //   }
  // }

  // const goToNextPage = () => {
  //   if (currentPage < totalNumberOfPages) {
  //     setCurrentPage(currentPage + 1)
  //   }
  // }
  return (
    <TableContainer>
      <Table aria-label="JobListing table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>
              <div className="svg">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="20"
                  height="21"
                  viewBox="0 0 20 21"
                  fill="none"
                >
                  <path
                    d="M15.8327 4.06665H4.16602C3.50297 4.06665 2.86709 4.33004 2.39825 4.79888C1.92941 5.26772 1.66602 5.90361 1.66602 6.56665V14.9C1.66602 15.563 1.92941 16.1989 2.39825 16.6678C2.86709 17.1366 3.50297 17.4 4.16602 17.4H15.8327C16.4957 17.4 17.1316 17.1366 17.6004 16.6678C18.0693 16.1989 18.3327 15.563 18.3327 14.9V6.56665C18.3327 5.90361 18.0693 5.26772 17.6004 4.79888C17.1316 4.33004 16.4957 4.06665 15.8327 4.06665ZM4.16602 15.7333C3.945 15.7333 3.73304 15.6455 3.57676 15.4892C3.42048 15.333 3.33268 15.121 3.33268 14.9V12.8833L6.08268 10.1417C6.23846 9.98896 6.44789 9.90344 6.66602 9.90344C6.88414 9.90344 7.09357 9.98896 7.24935 10.1417L12.841 15.7333H4.16602ZM16.666 14.9C16.666 15.121 16.5782 15.333 16.4219 15.4892C16.2657 15.6455 16.0537 15.7333 15.8327 15.7333H15.191L12.016 12.5416L12.7493 11.8083C12.9051 11.6556 13.1146 11.5701 13.3327 11.5701C13.5508 11.5701 13.7602 11.6556 13.916 11.8083L16.666 14.55V14.9ZM16.666 12.2L15.0993 10.6417C14.6244 10.1853 13.9913 9.93051 13.3327 9.93051C12.6741 9.93051 12.041 10.1853 11.566 10.6417L10.8327 11.375L8.43268 8.97498C7.95773 8.51868 7.32465 8.26384 6.66602 8.26384C6.00739 8.26384 5.3743 8.51868 4.89935 8.97498L3.33268 10.5333V6.56665C3.33268 6.34564 3.42048 6.13368 3.57676 5.97739C3.73304 5.82111 3.945 5.73332 4.16602 5.73332H15.8327C16.0537 5.73332 16.2657 5.82111 16.4219 5.97739C16.5782 6.13368 16.666 6.34564 16.666 6.56665V12.2Z"
                    fill="#0B435F"
                  />
                </svg>
              </div>
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Date Joined</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job, index) => {
            return <Row row={job} key={index} verify={verifyTalent} recruiter={verifyRecruiter} />;
          })}
        </TableBody>
      </Table>
      {/* {loading ? <Loading /> : <Pagination goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalNumberOfPages={totalNumberOfPages} />} */}
    </TableContainer>
  );
}
