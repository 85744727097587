import UserFeedback from "../../components/component-feedback/UserFeedback ";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, Fragment } from "react";
// import { Rating } from "@mui/material";
// eslint-disable-next-line 
import { KeyboardArrowDown, KeyboardArrowUp, Star } from "@mui/icons-material";
import Button from "@/component/buttons/Button";
import api from "../../../../services/api";

function Row({ row }) {
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState(row?.status)

  const handleResolve = (e) => {
    const newStatus = e.target.value;
    setStatus(newStatus);
    if (newStatus === 'resolved') {
      api.resolveFeedback(row?._id).then((res) => {
        if (res.status === 201) {
          setOpen(!open)
          window.location.reload()
        }
      }).catch(res => console.log(res))
    }
  }
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "180px",
            whiteSpace: "nowrap",
          }}
        >
          {row?.talent ? row?.talent?.first_name + " " + row?.talent?.last_name :'Deleted Talent'}
        </TableCell>
        <TableCell className="custom-cell">
          {" "}
          <span className="tag">{'Talent'}</span>{" "}
        </TableCell>
        <TableCell className="custom-cell">
          {row?.type}
        </TableCell>
        <TableCell className="custom-cell">
          <p className="text_overflow ">{row?.description}</p>
        </TableCell>
        <TableCell className="custom-cell">
          <span className="tag2">{row?.status}</span>{" "}
        </TableCell>
        <TableCell className="custom-cell">
          <IconButton
            aria-label="expand row"
            size="small"
            sx={{
              color: "#CC5600",
              fontSize: "14px",
            }}
            onClick={() => setOpen(!open)}
          >
            View Details {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }} className="hidden_details">
              <>
                <UserFeedback user={row} />
                <form className="form_con">
                  <>
                    <h4>Status</h4>
                    <div className="input-filter col-lg-3">
                      <select
                        className="form-select input_select"
                        aria-label="Default select example"
                        value={status}
                        onChange={handleResolve}
                      >
                        <option value="new">New</option>
                        <option value="resolved">Resolved</option>
                        <option value="pending">Pending</option>
                      </select>
                    </div>
                    <p className="input_info">Select to change status</p>
                  </>
                  <>
                    {/* <div className="my-3 ">
                      <Button text="Submit" primaryNull={false} small={true} onClick={handleResolve} />
                    </div> */}
                    <hr />
                    <div
                      className="my-3 closetoggle"

                    >
                      <Button
                        text="View Less"
                        primaryNull={true}
                        small={true}
                        onClick={() => setOpen(!open)}
                      />
                    </div>
                  </>
                </form>
              </>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function CollapsibleTable({ rows }) {
  return (
    <TableContainer>
      <Table aria-label="collapsible table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Name</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Type</TableCell>
            <TableCell>Feedback</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return <Row row={row} key={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
