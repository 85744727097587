import Modal from "@mui/material/Modal";
import Modalheader from "../Modalheader";
import "../modal.css";
import Box from "@mui/material/Box";
import InputField from "../../inputField/InputField";
import SelectField from "../../selectField/SelectField";
import Button from "@/component/buttons/Button";
import { Save } from "@mui/icons-material";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  maxWidth: 530,

  width: '100%',
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const AdminDetails = ({ open, details, handleClose, send, handleChange, handleSelectChange, selectedOption }) => {
  const userData = JSON.parse(sessionStorage.getItem('adminUser'))
  const options = [
    { value: "", label: "Admin Privilege" },
    { value: "local", label: "Local Admin" },
  ];

  if (userData && userData.role === "Super Admin") {
    options.push({ value: "super", label: "Super Admin" });
  }

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"Admin Modal"} handleClose={handleClose} />
          <div className="modal_body p-5">
            <h4 className="modal_title my-3">Enter Admin Details</h4>
            <div>
              <InputField
                name={'firstName'}
                value={details.firstName}
                onChange={handleChange}
                placeholder="Enter text..."
                title="First Name"
                isIconPresent={false}
                iconPosition="end"
                variant="outlined"
                className={"modal_input"}
              />
            </div>
            <div>
              <InputField
                name={'lastName'}
                value={details.lastName}
                onChange={handleChange}
                placeholder="Enter text..."
                title="Last Name"
                isIconPresent={false}
                iconPosition="end"
                variant="outlined"
                className={"modal_input"}
              />
            </div>{" "}
            <div>
              <InputField
                name={'email'}
                value={details.email}
                type={"email"}
                onChange={handleChange}
                placeholder="Email Address"
                title="Email Address"
                isIconPresent={false}
                iconPosition="end"
                variant="outlined"
                className={"modal_input"}
              />
            </div>{" "}
            <div>
              <InputField
                name={'phoneNumber'}
                type={"number"}
                value={details.phoneNumber}
                onChange={handleChange}
                placeholder="08162823220"
                title="Phone Number"
                isIconPresent={false}
                iconPosition="end"
                variant="outlined"
                className={"modal_input"}
              />
            </div>{" "}
            <div>
              <InputField
                name={'password'}
                type={"password"}
                value={details.password}
                onChange={handleChange}
                placeholder="default password"
                title="Password"
                isIconPresent={false}
                iconPosition="end"
                variant="outlined"
                className={"modal_input"}
              />
            </div>{" "}
            <div>
              <SelectField
                id="select-field"
                variant="outlined"
                defaultValue={selectedOption}
                options={options}
                onChange={handleSelectChange}
                title="Admin Priviledge"
              />
            </div>

            <div className="d-flex gap-3 align-items-center mt-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={() => handleClose()}
              />
              <Button
                text="Save"
                primaryNull={false}
                small={true}
                onClick={send}
                icon={<Save />}
              />

            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default AdminDetails;
