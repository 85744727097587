import axios from 'axios';

export const downloadFile = async (url, filename, setIsLoading) => {
  try {
    const response = await axios.get(url, {
      responseType: 'blob', // Important to get the response as a Blob
    });

    // Create a URL for the Blob
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = blobUrl;
    link.setAttribute('download', filename);

    // Append the link to the document body and trigger the download
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error('Error downloading the file', error);
  } 
};