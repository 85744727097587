import React from 'react'
import { Cancel, Person } from "@mui/icons-material";
import { IconButton } from '@mui/material';


const Modalheader = ({headText,handleClose}) => {
  return (
    <div className="modal_header p-2">
    <Person
      sx={{
        fontSize: "18px",
      }}
    />
    <h2>{headText}</h2>
    <IconButton onClick={handleClose}  sx={{
        color:'#fff'
    }} aria-label="add to shopping cart">
    <Cancel
      sx={{
        fontSize: "18px",
      }}
      
    />
    </IconButton>
    
  </div>
  )
}

export default Modalheader
