// const baseUrl = 'https://dashboard.frontters.com/api/v1';
export const baseUrl = process.env.REACT_APP_FRONTTERS_ADMIN_BASE_URL || 'https://frontters-admin-dashboard.onrender.com/api/v1'
const skillsUrl = 'https://frontter-engine.onrender.com/api/v1'

const authUrl = `${baseUrl}/auth`;

const routes = {
	register: () => `${authUrl}/signup`,
	login: () => `${authUrl}/signin`,
	profilepic: () => `${baseUrl}/upload-picture`,

	'get-recruiters': () => `${baseUrl}/recruiters`,
	'recruiter': (id) => `${baseUrl}/recruiters/${id}`,
	'recruiter-actions': (id, action) => `${baseUrl}/recruiters/${action}/${id}`,
	'export-recruiters': () => `${baseUrl}/recruiters/export`,


	'get-admin': () => `${baseUrl}/admin/me`,
	'get-admins': () => `${baseUrl}/admin`,
	'admin-by-id': (id) => `${baseUrl}/admin/${id}`,
	'admin-actions': (id, action) => `${baseUrl}/admin/${action}/${id}`,
	'create-admin': (action) => `${baseUrl}/admin/add-${action}`,
	'make-admin': (id, action) => `${baseUrl}/admin/make-${action}/${id}`,
	'export-admin': ()=>`${baseUrl}/admin/export`,



	'get-talents': () => `${baseUrl}/talents`,
	'talent': (id) => `${baseUrl}/talents/${id}`,
	'talent-actions': (id, action) => `${baseUrl}/talents/${action}/${id}`,
	'talents-projects': (id) => `${baseUrl}/talents/projects/${id}`,
	'export-talents': () => `${baseUrl}/talents/export`,

	'get-jobs': () => `${baseUrl}/jobs`,
	'job': (id) => `${baseUrl}/jobs/${id}`,
	'job-action': (id, action) => `${baseUrl}/jobs/${action}/${id}`,
	'job-applicants': () => `${baseUrl}/jobs/applicants`,
	'job-titles': () => `${baseUrl}/jobs/titles`,
	'reported-jobs':()=>`${baseUrl}/jobs/reports`,
	'resolve-jobReport': (id)=>`${baseUrl}/jobs/reports/${id}`,

	'get-audit-trail': () => `${baseUrl}/audit-trail`,
	'get-audit-overview': () => `${baseUrl}/audit-trail/overview`,

	'get-skills': () => `${skillsUrl}/admins/skills`,
	'delete-skill': (name)=>`${skillsUrl}/admins/skills/${name}`,

	'feedbacks': ()=>`${baseUrl}/feedbacks`,
	'resolve-feedback': (id)=>`${baseUrl}/feedbacks/resolve/${id}`,

	// export routes
	'audit-export': ()=>`${routes["get-audit-trail"]}/export`,

	// conversations
	'get-conversations': ()=>`${baseUrl}/conversations/admin`,
	'get-recruiter-chat-history': (id)=>`${baseUrl}/conversations/recruiter/${id}`,
	'get-talent-chat-history': (id)=>`${baseUrl}/conversations/talent/${id}`,
	'get-messages': (id)=>`${baseUrl}/conversations/messages/${id}`,
	'custom-messages': ()=>`${baseUrl}/conversations/custom-messages`,
	'delete-custom-messages': (id)=>`${baseUrl}/conversations/custom-messages/${id}`,
};

export default routes;
