import React, { useState, useEffect } from "react";
import Button from "@/component/buttons/Button";
import './css/UserAppliedJob.css'
import BreadCrumb from "../../../../component/bread-crumb/BreadCrumb";
import Loading from "../../components/loading/loading";
import ApplicantsTable from "./ApplicantsTable/ApplicantsTable";
import api from '@/services/api';
import CsvDownloadButton from 'react-json-to-csv'
import { useParams } from "react-router-dom";

const JobApplicants = () => {
 const [applicants, setApplicants] = useState([])
 const [loading, setLoading] = useState(false);
 const [search, setSearch] = useState('');
 const { id } = useParams();
 const breadCrumbProp = [
  {
   title: "Job Listing",
   link: "",
   previous: true,
  },
  {
   title: "Job",
   link: "",
   previous: true,
  },
  {
   title: "Applicants",
   link: "",
  },
 ];


 useEffect(() => {
  setLoading(true);
  api.getJobApplicants({job: id }).then(res => { setApplicants(res.data.applicants) }).catch(res => console.log(res)).finally(() => setLoading(false))
 }, [id])

 const handleSearch = (event) => {
  const searchTerm = event.target.value;
  setSearch(searchTerm);
 };

 // const filterByName = (data, searchText) => {
 //  const lowerSearchText = searchText.toLowerCase();
 //  return data?.filter(item => {

 //   const position = item.position.toLowerCase();


 //   return position.includes(lowerSearchText)
 //  });
 // };
 // const filteredJobs = filterByName(applicants, search)
 return (
  <div className="col-lg-10  col-12 py-3">
   <div className="row">
    <div className="col-12">
     <BreadCrumb context={breadCrumbProp} />
    </div>
    <div className="col-12">
     <div className="table-container">
      <div className="header_con mb-4 feedback-header py-3">
       <div className="row justify-content-between">
        <div className="form d-flex align-items-end   gap-5 col-lg-10">
         <div className="input-search col-lg-5 d-flex py-2">
          <div className="px-2">
           <img src="/search.svg" alt="" />
          </div>
          <input
           type="search"
           placeholder="Search for users"
           className="input_text"
           value={search}
           onChange={handleSearch}
          />
         </div>
         <div className="input-filter col-lg-5 d-flex py-2">
          <select className="input_text">
           <option selected>Filter by</option>
           <option value="1">One</option>
           <option value="2">Two</option>
           <option value="3">Three</option>
          </select>
         </div>

         <div className="d-flex gap-3">
          <div>
           <Button text="Sort" primaryNull={true} small={true} />
          </div>
          <div>
           <CsvDownloadButton data={applicants} filename={`applicants`}>
            <Button text="Export" primaryNull={false} small={true} />
           </CsvDownloadButton>
          </div>
         </div>
        </div>
       </div>
      </div>

      <div>
       {loading ? (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
         <Loading />
        </div>) :
        <ApplicantsTable jobs={applicants} />
       }
      </div>
     </div>

    </div>
   </div>
  </div>
 )
}

export default JobApplicants