import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import { useState } from "react";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@/component/buttons/Button";
import { formatDate } from "../../../../component/formatter";
import { useNavigate } from "react-router-dom";
import api from "../../../../services/api";
import ApplyReasonModal from "../../components/modal/ApplyReasonModal/ApplyReasonModal";

function Row({ row }) {
  // decline modals
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);

  const [age, setAge] = useState(row?.isJobApproved === false ? 'declined' : row?.isJobApproved === true ? 'approved' : 'pending');

  const [previousAge, setPreviousAge] = useState(age);

  const handleOpenAlert = () => {
    setOpenAlert(true);
    setIsDeclineModalOpen(false);
  };

  const navigate = useNavigate();

  const handleDecline = () => {
    if (currentJobId && declineReason !== "") {
      api.declineJob(currentJobId, declineReason)
        .then(() => {
          console.log("Job declined successfully");
          setOpenAlert(false);
        })
        .catch((res) => console.log(res));
    }
  };

  const handleChange = (event, id) => {
    const status = event.target.value
    setPreviousAge(age);
    setAge(status);
    if (status === 'pending') {
      api.actionJob(id, 'pend').then().catch((res) => console.log(res))
    } else if (status === 'approved') {
      api.actionJob(id, 'approve').then().catch((res) => console.log(res))
    } else {
      setCurrentJobId(id);
      setIsDeclineModalOpen(true);
    }
  };

  const handleCloseDeclineModal = () => {
    setIsDeclineModalOpen(false);
    setAge(previousAge);
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "180px",
            whiteSpace: "nowrap",
          }}
        >
          {row.title}
        </TableCell>
        <TableCell className="custom-cell">{row.company_name}</TableCell>
        <TableCell className="custom-cell">{formatDate(row.createdAt)}</TableCell>
        <TableCell className="custom-cell">{row.applicants}</TableCell>
        <TableCell className="custom-cell">

          <FormControl>
            <Select
              value={age}
              onChange={(event) => handleChange(event, row._id)}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={`muiSelect ${age}`}
            >
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"declined"}>Declined</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className="custom-cell">
          <div className="d-flex gap-3 col-lg-3">
            <div>
              <Button text="View" primaryNull={true} small={true} onClick={() => navigate(`/admin/jobdetails/${row._id}`)} />
            </div>
          </div>
        </TableCell>
      </TableRow>
      <ApplyReasonModal
        openAlert={openAlert}
        open={isDeclineModalOpen}
        handleClose={handleCloseDeclineModal}
        handleOpenAlert={handleOpenAlert}
        handleCloseAlert={() => setOpenAlert(false)}
        textValue={declineReason}
        handleChange={(e) => setDeclineReason(e.target.value)}
        handleDecline={handleDecline}
      />
    </Fragment>
  );
}

export default function JobListingTable({ jobs, }) {

  return (
    <TableContainer>
      <Table aria-label="JobListing table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Job Title</TableCell>
            <TableCell>Recruiter</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Applicants</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job, index) => {
            return <Row row={job} key={index} />;
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
