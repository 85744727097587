import React, { useEffect } from "react";
import { useState } from "react";
import "./css/Feedback.css";
import Button from "@/component/buttons/Button";
import CollapsibleTable from "./reported-job-table/reportedjobtable";
// import ReportJobFeedback from "../components/component-reportedjob/ReportJobFeedback";
import api from "../../../services/api";
import Pagination from "../components/pagination/Pagination";
import Loading from "../components/loading/loading";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputField from "../components/inputField/InputField";
import CsvDownloadButton from "react-json-to-csv";

const ReportedJob = () => {
  // const [preferredViewIcon, setpreferredViewIcon] = useState(true);
  // const [showdropDown, setShowDropDown] = useState(false);
  // const [isTableDispaly, setIsTableDispaly] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reports, setReports] = useState([]);
  const [totalReports, setTotalReports] = useState("");
  const [itemsPerPage] = useState(7);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterType, setFilterType] = useState("");
  const [filterOptions, setFilterOptions] = useState({
    status: "",
    startDate: "",
    endDate: "",
    talentName: "",
  });

  useEffect(() => {
    setIsLoading(true);
    const filters = {};
    if (filterOptions.status) {
      filters.status = filterOptions.status;
    }
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    if (filterOptions.talentName) {
      filters.talentName = filterOptions.talentName;
    }
    api
      .getReportedJobs({
        ...filters,
        pageNumber: currentPage,
        pageSize: itemsPerPage,
      })
      .then((res) => {
        setReports(res.data.reports);
        setTotalReports(res.data.totalCount);
      })
      .catch((res) => console.log(res))
      .finally(() => setIsLoading(false));
    // eslint-disable-next-line
  }, [filterOptions, currentPage]);

  // pagination
  const totalNumberOfPages = Math.ceil(totalReports / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  // const preferredView = () => {
  //   setpreferredViewIcon(!preferredViewIcon);
  //   setShowDropDown(!showdropDown);
  //   setIsTableDispaly(!isTableDispaly);
  // };

  const handleChange = (event) => {
    const { name, value } = event.target;

    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      talentName: searchTerm,
    }));
  };
  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div className="top-container">
            <h2 className="name my-2">Reported Jobs</h2>
          </div>
        </div>

        <div className="col-12">
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div className={`input-search col-lg-3 d-flex py-2`}>
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for talents"
                      className="input_text"
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter ${filterType === "date" && "col-lg-2 "
                      }   col-xl-3  col-lg-4 d-flex pt-2 my-3 my-lg-0`}
                  >
                    <select
                      className={"input_text"}
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value={""}>
                        Filter Options
                      </option>
                      <option value="date">Date</option>
                      <option value="status">Status</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-center py-2">
                      {filterType === "status" && (
                        <FormControl>
                          <Select
                            name="status"
                            value={filterOptions.status}
                            onChange={handleChange}
                            inputProps={{ "aria-label": "Without label" }}
                            className={`muiSelect ${filterOptions.status}`}
                          >
                            <MenuItem value={""}>Select Status</MenuItem>
                            <MenuItem value={"new"}>new</MenuItem>
                            <MenuItem value={"resolved"}>Resolved</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: "-20px", paddingRight: '30px' }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "100%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="d-flex gap-3 col-lg-2 my-lg-0 my-4">
                    {filterType !== "date" ? (
                      <div>
                        <Button text="Sort" primaryNull={true} small={true} />
                      </div>
                    ) : null}
                    <div>
                      <CsvDownloadButton
                        data={reports}
                        filename={`reportedJobs-${currentPage}`}
                      >
                        <Button
                          text="Export"
                          primaryNull={false}
                          small={true}
                        />
                      </CsvDownloadButton>
                    </div>
                  </div>
                </div>
                {/* <div className=" switch_col col-lg-1">
                  <div
                    className="switch_view d-flex  justify-content-between "
                    onClick={() => setShowDropDown(!showdropDown)}
                  >
                    <div
                      className={`d-${
                        showdropDown ? "flex" : "none"
                      } flex-column  pick_view gap-2`}
                    >
                      {!preferredViewIcon ? (
                        <label
                          htmlFor="grid"
                          className="switch_icon "
                          onClick={preferredView}
                        >
                          <img src="/grid.svg" alt="" />
                        </label>
                      ) : (
                        <label
                          htmlFor="grid"
                          className="switch_icon "
                          onClick={preferredView}
                        >
                          <img src="/search.svg" alt="" />
                        </label>
                      )}
                    </div>
                    {preferredViewIcon ? (
                      <div className="switch_icon">
                        <img src="/grid.svg" alt="" />
                      </div>
                    ) : (
                      <div className="switch_icon">
                        <img src="/search.svg" alt="" />
                      </div>
                    )}
                    <div
                      className="switch_icon "
                      onClick={() => setShowDropDown(!showdropDown)}
                    >
                      <img src="/chevrond.svg" alt="" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>

            <>
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>

                  <CollapsibleTable rows={reports} />;


                  {/* {isTableDispaly ? (
                    <>
                      {reports?.map((report, index) => {
                        return (
                          <ReportJobFeedback user={report} key={index} />
                        )
                      })}
                    </>
                  ) : (
                    <CollapsibleTable rows={reports} />
                  )} */}
                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </>
              )}
            </>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReportedJob;
