import React from "react";
import TextField from "@mui/material/TextField";

const Textarea = ({
  id,
  label,
  value,
  onChange,
  rows,
  placeholder,
  helperText,
  title,
  maxLength
}) => {
  return (
    <div>
      <label className="input_label">{title}</label>
      <TextField
        id={id}
        label={label}
        multiline
        rows={rows}
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        fullWidth
        helperText={helperText}
        inputProps={{maxLength:maxLength}}

      />
    </div>
  );
};

export default Textarea;
