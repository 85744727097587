import { useEffect, useState } from "react";
import "../dashboardreview/css/Dashboardreview.css";
import { useNavigate } from "react-router-dom";
import Box from '@mui/material/Box';
import { Button as MuiButton, styled, Skeleton } from "@mui/material";
import Button from "../../component/buttons/Button";
import { Search } from "@mui/icons-material";
import IndexPagination from "../../component/pagination-index/IndexPagination";
import PersonCard from "../../component/cards/job-card/PersonCard";
import api from "../../services/api";

const ColorButton2 = styled(MuiButton)(({ theme }) => ({
  margin: '0 3px',
  padding: '8px',
  borderRadius: '1000px',
  textTransform: 'none',
  color: '#CC5600',
  backgroundColor: '#f0f0f0',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
  '&.active': {
    backgroundColor: '#FAEFE6',
  },
}));

const ColorButton3 = styled(MuiButton)(({ theme }) => ({
  margin: '0 3px',
  padding: '8px',
  borderRadius: '1000px',
  textTransform: 'none',
  color: '#FFB700',
  backgroundColor: '#f0f0f0',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
  '&.active': {
    backgroundColor: '#FFF8E6',
  },
}));

const ColorButton4 = styled(MuiButton)(({ theme }) => ({
  margin: '0 3px',
  padding: '8px',
  borderRadius: '1000px',
  textTransform: 'none',
  color: 'black',
  backgroundColor: '#f0f0f0',
  '&:hover': {
    backgroundColor: '#d0d0d0',
  },
  '&.active': {
    backgroundColor: '#ECECEC',
  },
}));

const InitiateChat = () => {
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [activeTab, setActiveTab] = useState(1);
  const [searchTerm, setSearchTerm] = useState('');

  const [recruiters, setRecruiters] = useState([]);
  const [totalRecruiters, setTotalRecruiters] = useState("");
  const [recruitersLoading, setRecruitersLoading] = useState(false);

  const [talents, setTalents] = useState([]);
  const [totalTalents, setTotalTalents] = useState("");
  const [talentsLoading, setTalentsLoading] = useState(false);

  const [admins, setAdmins] = useState([]);
  const [totalAdmins, setTotalAdmins] = useState("");
  const [adminsLoading, setAdminsLoading] = useState(false);

  useEffect(() => {
    if (activeTab === 1) {
      setRecruitersLoading(true);
      api.getRecuriters({ pageNumber: currentPage, pageSize: itemsPerPage })
        .then((res) => {
          setTotalRecruiters(res.data?.totalRecruiters);
          setRecruiters(res.data.recruiters);
        })
        .catch((err) => console.log(err))
        .finally(() => setRecruitersLoading(false));
    } else if (activeTab === 2) {
      setTalentsLoading(true);
      api.getTalents({ pageNumber: currentPage, pageSize: itemsPerPage })
        .then((res) => {
          setTalents(res.data.talents);
          setTotalTalents(res.data?.totalTalents);
        })
        .catch((err) => console.log(err))
        .finally(() => setTalentsLoading(false));
    } else if (activeTab === 3) {
      setAdminsLoading(true);
      api.getAdmins({ pageNumber: currentPage, pageSize: itemsPerPage })
        .then((res) => {
          setAdmins(res.data.admins);
          setTotalAdmins(res.data.totalAdmins);
        })
        .catch((err) => console.log(err))
        .finally(() => setAdminsLoading(false));
    }
  }, [activeTab, currentPage, itemsPerPage]);

  const onPageChange = (newPage) => {
    if (newPage >= 1 && newPage <= pageCount) {
      setCurrentPage(newPage);
    }
  };

  const pageCount = Math.ceil(
    (activeTab === 1 ? totalRecruiters :
    activeTab === 2 ? totalTalents :
    activeTab === 3 ? totalAdmins : 0) / itemsPerPage
  );

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);
  };

  const handleTabChange = (newValue) => {
    setActiveTab(newValue);
    setCurrentPage(1);
  };

  const renderContent = () => {
    let content;
    let loading;

    switch (activeTab) {
      case 1:
        content = recruiters;
        loading = recruitersLoading;
        break;
      case 2:
        content = talents;
        loading = talentsLoading;
        break;
      case 3:
        content = admins;
        loading = adminsLoading;
        break;
      default:
        content = [];
        loading = false;
    }

    const filteredContent = content.filter(item => {
      const name = `${item.first_name || item.firstName} ${item.last_name || item.LastName}`.toLowerCase();
      return name.includes(searchTerm.toLowerCase());
    });

    return (
      loading ? (
        Array.from(new Array(4)).map((_, idx) => (
          <Skeleton variant="rectangular" sx={{width:'320px', maxWidth:'400px'}} height={140} key={idx} />
        ))
      ) : (
        filteredContent.map((item, idx) => (
          <PersonCard key={idx} data={item} />
        ))
      )
    );
  };

  return (
    <div className="col-lg-10 col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div className='d-lg-flex gap-2 justify-content-between mb-3'>
            <h4 className="name my-2">Conversations</h4>
            <div className="d-flex gap-2">
              <Button
                text="Add Custom Questions"
                primaryNull={true}
                small={true}
                onClick={()=>navigate('/admin/custom-messages')}
              />
              <Button
                text="My Conversations"
                primaryNull={true}
                small={true}
                onClick={()=>navigate('/admin/chat-history')}
              />
            </div>
          </div>
        </div>
        <section className="d-flex flex-column bg-white shadow">
          <Box display="flex" justifyContent="space-between" alignItems="center" padding={'10px'}>
            <Box display="flex" gap='5px'>
              <ColorButton2
                className={activeTab === 1 ? 'active' : ''}
                onClick={() => handleTabChange(1)}
              >
                Recruiters
              </ColorButton2>
              <ColorButton3
                className={activeTab === 2 ? 'active' : ''}
                onClick={() => handleTabChange(2)}
              >
                Talents
              </ColorButton3>
              <ColorButton4
                className={activeTab === 3 ? 'active' : ''}
                onClick={() => handleTabChange(3)}
              >
                Admin
              </ColorButton4>
            </Box>
            <Box position='relative' backgroundColor='#EBEBEB' padding='4px 8px' borderRadius={'8px'}>
              <Search sx={{ position: 'absolute', right: '5px', top: '5px' }} />
              <input type="text" name="search" value={searchTerm} placeholder="Search User" id="search" style={{ border: 'none', outline: 'none', backgroundColor: 'transparent' }} onChange={handleSearch} />
            </Box>
          </Box>
          <Box minHeight={300} margin={'15px 0'} display={'flex'} flexWrap={'wrap'} gap={'20px'}>
            {renderContent()}
          </Box>
        </section>
        <div className="d-flex justify-content-center py-2" style={{ backgroundColor: '#2E2E30' }}>
          <IndexPagination pageCount={pageCount} currentPage={currentPage} onPageChange={onPageChange} />
        </div>
      </div>
    </div>
  );
};

export default InitiateChat;
