import Button from "@/component/buttons/Button";
import "../css/Dashboardreview.css";
import Topbar from "../components/topbar/Topbar";
import "./css/JobDashboard.css";
import { useEffect, useState } from "react";
import api from "@/services/api";
import { useNavigate } from "react-router-dom";
import Pagination from "../components/pagination/Pagination";
import Loading from "../components/loading/loading";

const JobDashboard = () => {
 const [searchQuery, setSearchQuery] = useState("");
 const [dateSortingOrder, setDateSortingOrder] = useState("asc");
 const [totalJobs, setTotalJobs] = useState("");
 const [totalClosedJobs, setTotalClosedJobs] = useState("");
 const [jobs, setJobs] = useState([]);
 const [currentPage, setCurrentPage] = useState(1);
 const [itemsPerPage] = useState(4);
 const [loading, setLoading] = useState(false);

 const navigate = useNavigate();

 useEffect(() => {
  api
   .getJobs(currentPage, itemsPerPage)
   .then((res) => {
    setLoading(true);
    const sortedJobs = res.data.jobsWithApplicantCount.sort((a, b) => {
     if (dateSortingOrder === "asc") {
      return new Date(a.createdAt) - new Date(b.createdAt);
     } else {
      return new Date(b.createdAt) - new Date(a.createdAt);
     }
    });

    setTotalClosedJobs(res.data?.totalNumberOfClosedJobs);
    setTotalJobs(res.data?.totalNumberOfJobs);
    setJobs(sortedJobs);
   })
   .catch((err) => console.log(err))
   .finally(() => setLoading(false));
 }, [currentPage, itemsPerPage, dateSortingOrder]);

 const totalNumberOfPages = Math.ceil(totalJobs / itemsPerPage);
 const goToPreviousPage = () => {
  if (currentPage > 1) {
   setCurrentPage(currentPage - 1);
  }
 };

 const goToNextPage = () => {
  if (currentPage < totalNumberOfPages) {
   setCurrentPage(currentPage + 1);
  }
 };

 const handleDelete = (id) => {
  api
   .deleteJob()
   .then(() => {
    setJobs((preJobs) => preJobs.filter((job) => job._id !== id));
   })
   .catch((err) => console.log(err));
 };

 return (
  <div className="col-lg-10   col-12 py-3">
   <div className="row gap-4">
    <div className="col-12">
     <Topbar
      name={"Jobs"}
      briefInfo={"Welcome to the Frontters platform"}
      boxes={[
       {
        number: loading ? <Loading /> : `${totalJobs}`,
        label: "Jobs",
        backgroundcolor: false,
       },
       {
        number: loading ? <Loading /> : `${totalClosedJobs}`,
        label: "Closed",
        backgroundcolor: true,
       },
      ]}
     />
    </div>
    <div className="col-12">
     <div className="table-container">
      <div className="header_con mb-4">
       <div className="form d-flex gap-3">
        <div className="input-search col-lg-4 d-flex">
         <div className="px-2">
          <i className="fa-solid fa-magnifying-glass"></i>
         </div>
         <input
          type="search"
          placeholder="Search Job"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
         />
        </div>
        <div className="input-filter d-flex col-lg-3">
         <div className="px-2 d-flex">
          <i className="fa-regular fa-calendar-days"></i>
          {dateSortingOrder === "asc" ? (
           <i className="fa-solid fa-arrow-up"></i>
          ) : (
           <i className="fa-solid fa-arrow-down"></i>
          )}
         </div>
         <select
          className="form-select input_select"
          aria-label="Default select example"
          value={dateSortingOrder}
          onChange={(e) => setDateSortingOrder(e.target.value)}
          key={dateSortingOrder}
         >
          <option value="asc">Ascending</option>
          <option value="desc">Descending</option>
         </select>
        </div>
       </div>
      </div>
      <div className="table-con table-responsive old_table ">
       <table className="table _table table-borderless">
        <thead>
         <tr>
          <th scope="col">
           <div className="svg">
            <svg
             xmlns="http://www.w3.org/2000/svg"
             width="20"
             height="21"
             viewBox="0 0 20 21"
             fill="none"
            >
             <path
              d="M15.8327 4.06665H4.16602C3.50297 4.06665 2.86709 4.33004 2.39825 4.79888C1.92941 5.26772 1.66602 5.90361 1.66602 6.56665V14.9C1.66602 15.563 1.92941 16.1989 2.39825 16.6678C2.86709 17.1366 3.50297 17.4 4.16602 17.4H15.8327C16.4957 17.4 17.1316 17.1366 17.6004 16.6678C18.0693 16.1989 18.3327 15.563 18.3327 14.9V6.56665C18.3327 5.90361 18.0693 5.26772 17.6004 4.79888C17.1316 4.33004 16.4957 4.06665 15.8327 4.06665ZM4.16602 15.7333C3.945 15.7333 3.73304 15.6455 3.57676 15.4892C3.42048 15.333 3.33268 15.121 3.33268 14.9V12.8833L6.08268 10.1417C6.23846 9.98896 6.44789 9.90344 6.66602 9.90344C6.88414 9.90344 7.09357 9.98896 7.24935 10.1417L12.841 15.7333H4.16602ZM16.666 14.9C16.666 15.121 16.5782 15.333 16.4219 15.4892C16.2657 15.6455 16.0537 15.7333 15.8327 15.7333H15.191L12.016 12.5416L12.7493 11.8083C12.9051 11.6556 13.1146 11.5701 13.3327 11.5701C13.5508 11.5701 13.7602 11.6556 13.916 11.8083L16.666 14.55V14.9ZM16.666 12.2L15.0993 10.6417C14.6244 10.1853 13.9913 9.93051 13.3327 9.93051C12.6741 9.93051 12.041 10.1853 11.566 10.6417L10.8327 11.375L8.43268 8.97498C7.95773 8.51868 7.32465 8.26384 6.66602 8.26384C6.00739 8.26384 5.3743 8.51868 4.89935 8.97498L3.33268 10.5333V6.56665C3.33268 6.34564 3.42048 6.13368 3.57676 5.97739C3.73304 5.82111 3.945 5.73332 4.16602 5.73332H15.8327C16.0537 5.73332 16.2657 5.82111 16.4219 5.97739C16.5782 6.13368 16.666 6.34564 16.666 6.56665V12.2Z"
              fill="#0B435F"
             />
            </svg>
           </div>
          </th>
          <th scope="col">Title</th>
          <th scope="col">Applicant</th>
          <th scope="col">Recruiters</th>

          <th scope="col">Date Joined</th>
          <th scope="col">Status</th>
          <th scope="col">Action</th>
         </tr>
        </thead>
        {loading ? (
         <Loading />
        ) : (
         <tbody>
          {jobs

           .filter((user) => {
            return user.title.toLowerCase().includes(searchQuery.toLowerCase());
           })

           .map((user) => {
            const createdAt = user.createdAt;
            const date = new Date(createdAt);
            const options = { year: "numeric", month: "short", day: "numeric" };
            const formattedDate = date.toLocaleDateString("en-US", options);
            return (
             <tr key={user._id}>
              <td>
               <div className="user-image">
                <img
                 src={
                  user.recruiter?.href_link ||
                  "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
                 }
                 alt=""
                />
               </div>
              </td>
              <td>{user.title}</td>
              <td>{user.applicants}</td>
              <td>{user.company_name}</td>
              <td>{formattedDate}</td>
              <td>
               <span
                style={{
                 backgroundColor: `${user.status === "Open" ? "green" : "red"}`,
                 color: "white",
                }}
               >
                {user.status}
               </span>
              </td>
              <td>
               <div className="d-flex">
                <div className="d-flex gap-2">
                 <Button
                  text="View"
                  primaryNull={true}
                  small={true}
                  onClick={() => navigate(`/admin/jobdetails/${user._id}`)}
                 />

                 <Button
                  redBtn={true}
                  text={<i className="fa-solid fa-trash"></i>}
                  primaryNull={true}
                  small={true}
                  onClick={() => handleDelete(user._id)}
                 />
                </div>
               </div>
              </td>
             </tr>
            );
           })}
         </tbody>
        )}
       </table>
      </div>

      {loading ? (
       <Loading />
      ) : (
       <Pagination
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalNumberOfPages={totalNumberOfPages}
       />
      )}
     </div>
    </div>
   </div>
  </div>
 );
};

export default JobDashboard;
