import { Link } from 'react-router-dom';
import classNames from '../../../utils/conditional-css-classes/classNames';
import style from './css/JobCard.module.sass';
import service from '@/services';

function PersonCard({ box, data, tag, preview, callback }) {

	const getFullName = () => {
		if (data.role === 'Super Admin') {
			return `${data?.firstName || ''} ${data?.lastName || ''}`;
		} else {
			return `${data?.first_name || ''} ${data?.last_name || ''}`;
		}
	};

	const getTitle = () => {
		if (data.role === 'talent') {
			return `${data?.title || 'No Designation'}`;
		} else {
			return `${data?.company_name || data.role}`;
		}
	};


	return (
		<div className={style['card-job']}>
			<div className={`${style.header} d-flex align-items-center justify-content-between ${classNames(box && style['has-box'])}`}>
				<div className={`d-flex align-items-center ${classNames(!box && `gap-1`)}`}>
					{[1, 2, 3].map((x, y) => (
						<i className={`${classNames(box && `${style[`box-${y + 1}`]}`)} ${style.box}`} key={y}></i>
					))}
				</div>
			</div>

			<div className={`${style.context} d-flex align-items-start`}>
				<div className={`${style['con-class']} d-flex gap-3`}>

					<div className={style['avatar-container']} style={{ border: '5px solid #FFB700', width: '85px', height: '85px' }}>
						<img src={data?.profile_picture?.href_link || "https://t3.ftcdn.net/jpg/06/33/54/78/240_F_633547842_AugYzexTpMJ9z1YcpTKUBoqBF0CUCk10.jpg"} alt="Avatar" className={style['avatar-img']} />
					</div>

					<div className={`${style['class-1']} d-flex flex-column gap-2`} >
						<h3 title={getFullName()}>
							{getFullName() ? service.truncateText(getFullName()) : `company name`}
						</h3>
						<h2 title={getTitle()} className="d-flex align-items-center gap-3">
							{getTitle() ? service.truncateText(getTitle()) : `job title`}
						</h2>
						<h4 title={`${data?.location?.resident_state} ${data?.location?.resident_country}`}>
							{data?.location?.resident_state ? service.truncateText(service.titleCase(data?.location.resident_state)) : `state`}, {data?.location?.resident_country ? service.truncateText(service.titleCase(data?.location.resident_country)) : `country`}
						</h4>
					</div>

					<div className={`${style['class-2']} d-flex flex-column align-items-end justify-content-between`} style={{ marginTop: 'auto', marginLeft: 'auto' }}>
						<>
							<Link to={`/admin/chat-history/${data?._id}`} className={``}>
								<div className={`fa-solid fa-arrow-right ${style['arrow-btn']} ${style.outline}`}>
									<i className={``}></i>
								</div>
							</Link>
						</>
					</div>
				</div>
			</div>
		</div>
	);
}

export default PersonCard;
