import React, { useState, useEffect } from "react";
import Topbar from "../components/topbar/Topbar";
import api from "@/services/api";
import Loading from "../components/loading/loading";


const Skills = () => {
  const [skills, setSkills] = useState([]);
  const [loading, setLoading] = useState(false);

  const [filterOptions, setFilterOptions] = useState({
    skill: "",
  });


  useEffect(() => {
    setLoading(true);
    api.getSkills().then(res => setSkills(res.data?.result)).catch(res => console.log(res)).finally(() => setLoading(false))
  }, [filterOptions]);

  const handleSkillDelete = (name) => {
    setLoading(true)
    api.deleteSkill(name).then(res => {
      if (res.status === 200) {
        setSkills(prevSkills => prevSkills.filter(skill => skill.name !== name));
      }
      setLoading(false);
    }).catch(res => console.log(res))
  }


  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      skill: searchTerm,
    }));
  };
  ;

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <Topbar name={"Skills"} />
        </div>
        <div className="col-12">
          <div className="table-container" style={{ height: '100vh' }}>
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search col-lg-5
                   d-flex py-2`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <div>
                      <label htmlFor="skill"></label>
                      <input
                        type="search"
                        placeholder="e.g UI Developer"
                        className="input_text"
                        value={filterOptions.skill}
                        onChange={handleSearch}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <div className="d-flex flex-wrap gap-2">
                  {
                    skills.map((x, y) => {
                      return (
                        <div key={x.id} className="bg-dark text-white rounded-pill px-3 py-2 d-flex justify-content-between align-items-center" style={{ width: '120px' }}>
                          <p className="m-0 text-white">{x.name}</p>
                          <button type="button" onClick={() => handleSkillDelete(x.name)} className="ms-2 text-white">{'x'}</button>
                        </div>
                      )
                    })
                  }
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Skills;
