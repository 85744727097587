import { formatDate } from "../../../../component/formatter";


export default function ActivityTable({activities}) {

  return (
    <table class="table table-borderless activity_table" style={{
      background: 'transparent'
    }}>
      <thead>
        <tr>
          <th scope="col">Activity Date</th>
          <th scope="col">Activity</th>
        </tr>
      </thead>
      <tbody >
        {activities.map((activity) => {
          return (
            <tr >
              <td className="py-3">{formatDate(activity.createdAt)}</td>
              <td className="py-3">{activity.action}</td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}
