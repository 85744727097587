import React, { useState, useEffect } from "react";
import Topbar from "../components/topbar/Topbar";
import Button from "@/component/buttons/Button";
import JobListingTable from "./jobListingTable/JobListingTable";
import "./css/JobListing.css";
import api from "@/services/api";
import Loading from "../components/loading/loading";
import Pagination from "../components/pagination/Pagination";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import InputField from "../components/inputField/InputField";
import { Search, Cancel } from "@mui/icons-material";
import { IconButton, Radio } from "@mui/material";
import CsvDownloadButton from 'react-json-to-csv'

const JobListing = () => {
  const [jobs, setJobs] = useState([]);
  const [jobTitles, setjobTitles] = useState([]);
  const [totalJobs, setTotalJobs] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(7);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [applicantsSortOrder, setApplicantsSortOrder] = useState("asc");
  const [filterType, setFilterType] = useState("");
  const [displayTitle, setDisplayTitle] = useState(false);


  const [filterOptions, setFilterOptions] = useState({
    jobTitle: "",
    status: "",
    startDate: "",
    endDate: "",
    recruiterName: "",
  });

  const handleApplicantsSort = () => {
    // Toggle sorting order
    const newSortOrder = applicantsSortOrder === "asc" ? "desc" : "asc";
    setApplicantsSortOrder(newSortOrder);

    // Sort users array based on the number of applicants
    const sortedUsers = [...jobs].sort((a, b) => {
      const applicantsA = a.applicants;
      const applicantsB = b.applicants;

      if (newSortOrder === "asc") {
        return applicantsA - applicantsB;
      } else {
        return applicantsB - applicantsA;
      }
    });

    setJobs(sortedUsers);
    setIsOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    const filters = {};
    if (filterOptions.jobTitle) {
      filters.jobTitle = filterOptions.jobTitle;
    }
    if (filterOptions.status) {
      filters.status = filterOptions.status;
    }
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    api
      .getJobs({ ...filters, pageNumber: currentPage, pageSize: itemsPerPage })
      .then((res) => {
        setJobs(res.data.jobs);
        setTotalJobs(res.data.totalCount);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [filterOptions, currentPage, itemsPerPage]);

  useEffect(() => {
    api
      .getJobTitles()
      .then((res) => {
        setjobTitles(res.data?.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      recruiterName: searchTerm,
    }));
  };

  const totalNumberOfPages = Math.ceil(totalJobs / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  // Job title area
  // job title search
  const [searchJobTitle, setSearchJobTitle] = useState("");
  const [selectedItem, setSelectedItem] = useState(null);

  const handleRadioChange = (item) => {
    setSelectedItem(item);
    setFilterOptions((prev) => ({
      ...prev,
      jobTitle: item._id,
    }));
  };

  const handleJobTitleSearchChange = (event) => {
    setDisplayTitle(true);
    setSearchJobTitle(event.target.value);
  };

  const filteredJobTitles = jobTitles.filter((item) =>
    item._id.toLowerCase().includes(searchJobTitle.toLowerCase())
  );

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <Topbar name={"Job Listing"} />
        </div>
        <div className="col-12">
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search ${filterType === "" && "col-lg-5"
                      } col-lg-4 d-flex py-2 mt-1`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for jobs by recruiters name"
                      className="input_text"
                      value={filterOptions.recruiterName}
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter  ${filterType === "" && "col-lg-5"
                      } col-lg-3 d-flex pt-3`}
                  >
                    <select
                      className="input_text"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value="">
                        Filter by
                      </option>
                      <option value="status">status</option>
                      <option value="date">date</option>
                      <option value="job title">job title</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-end py-2 my-4 my-lg-0">
                      {filterType === "status" && (
                        <FormControl>
                          <Select
                            name="status"
                            value={filterOptions.status}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            className={`muiSelect ${filterOptions.status}`}
                          >
                            <MenuItem value={""}>Select Status</MenuItem>
                            <MenuItem value={"pending"}>Pending</MenuItem>
                            <MenuItem value={"approved"}>Approved</MenuItem>
                            <MenuItem value={"declined"}>Declined</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: '-20px' }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "80%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                      {filterType === "job title" && (
                        <div className="d-flex flex-column align-items-center input_select_con ">
                          <div
                            className="input_text"
                            style={{
                              marginBottom: "10px",
                              padding: "5px",
                              minWidth: "150px",
                              position: "relative",
                            }}
                          >
                            <InputField
                              placeholder={"Search For Title"}
                              type={"text"}
                              value={searchJobTitle}
                              onChange={handleJobTitleSearchChange}
                              onFocus={() => setDisplayTitle(true)}
                              isIconPresent={true}
                              icon={Search}
                              iconPosition="start"
                            />
                          </div>
                          {displayTitle && (
                            <div className="select_options">
                              <div
                                className="d-flex justify-content-end "
                                style={{
                                  position: "absolute",
                                  right: "0",
                                  top: "0",
                                }}
                              >
                                <IconButton
                                  onClick={() => setDisplayTitle(false)}
                                >
                                  <Cancel
                                    sx={{
                                      width: "18px",
                                      color: "red",
                                    }}
                                  />
                                </IconButton>
                              </div>

                              <ul style={{ listStyleType: "none", }} className="py-1 px-0">
                                {filteredJobTitles.map((item) => (
                                  <li
                                    key={item._id}
                                    className="d-flex  align-items-center mb-1 gap-3"
                                  >
                                    <div className="">
                                      <Radio
                                        type="radio"
                                        id={item._id}
                                        name="selectedItem"
                                        value={item._id}
                                        checked={selectedItem === item}
                                        onChange={() => handleRadioChange(item)}
                                        sx={{
                                          color: "#CB5600",

                                          "&.Mui-checked": {
                                            color: "#CB5600",
                                          },
                                          '& .MuiSvgIcon-root': {
                                            fontSize: 18,
                                          },
                                        }}
                                      />
                                    </div>
                                    <label
                                      htmlFor={item._id}
                                      className="flex-2 m-0 title_text"
                                    >{` ${item._id} (${item.count})`}</label>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}

                  <div className="d-flex gap-3 my-3 my-lg-0">
                    <div className="position-relative">
                      <Button
                        text="Sort"
                        primaryNull={true}
                        small={true}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div
                          className="position-absolute text-white p-2 rounded mt-2"
                          style={{
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            minWidth: "180px",
                            backgroundColor: "#CC5600",
                          }}
                        >
                          <Button
                            text={`By Applicants`}
                            primaryNull={false}
                            small={true}
                            onClick={handleApplicantsSort}
                          />
                        </div>
                      )}
                    </div>
                    <div>
                      <CsvDownloadButton data={jobs} filename={`joblisting-${currentPage}`} >
                        <Button text="Export" primaryNull={false} small={true} />
                      </CsvDownloadButton>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <>
                  <JobListingTable jobs={jobs} />

                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JobListing;
