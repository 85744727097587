import React from "react";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import api from "@/services/api";
import "./css/JobDetail.css";
import BreadCrumb from "@/component/bread-crumb/BreadCrumb";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Button from "@/component/buttons/Button";
import ApplyReasonModal from "../components/modal/ApplyReasonModal/ApplyReasonModal";

const JobDetail = () => {
  const { id } = useParams();
  const [job, setJob] = useState({});
  const [jobApplicants, setJobApplicants] = useState(0);
  const [age, setAge] = useState('pending');
  const [previousAge, setPreviousAge] = useState(age);
  const navigate = useNavigate()

  // decline modals
  const [isDeclineModalOpen, setIsDeclineModalOpen] = useState(false)
  const [openAlert, setOpenAlert] = useState(false);
  const [declineReason, setDeclineReason] = useState("");
  const [currentJobId, setCurrentJobId] = useState(null);

  const handleOpenAlert = () => {
    setOpenAlert(true);
    setIsDeclineModalOpen(false);
  };


  useEffect(() => {
    api
      .getJob(id)
      .then((res) => {
        setJob(res.data?.job);
        setJobApplicants(res.data?.applicantCount)
        if (res.data?.job?.isJobApproved === true) {
          setAge('approved');
        } else if (res.data?.job?.isJobApproved === false) {
          setAge('declined');
        }
      })
      .catch((err) => console.log(err));
  }, [id]);


  const breadCrumbProp = [
    {
      title: "Job Listing",
      link: "",
      previous: true,
    },
    {
      title: "Job",
      link: "",
    },
  ];

  const handleDecline = () => {
    if (currentJobId && declineReason !== "") {
      api.declineJob(currentJobId, declineReason)
        .then(() => {
          console.log("Job declined successfully");
          setOpenAlert(false);
        })
        .catch((res) => console.log(res));
    }
  };

  const handleChange = (event, id) => {
    const status = event.target.value
    setPreviousAge(age);
    setAge(status);
    if (status === 'pending') {
      api.actionJob(id, 'pend').then().catch((res) => console.log(res))
    } else if (status === 'approved') {
      api.actionJob(id, 'approve').then().catch((res) => console.log(res))
    } else {
      setCurrentJobId(id);
      setIsDeclineModalOpen(true);
    }
  };

  const handleCloseDeclineModal = () => {
    setIsDeclineModalOpen(false);
    setAge(previousAge);
  };

  return (
    <div id="JOB_DETAILS_CONTAINER" className="col-lg-10 col-12">
      <div className="job-container container-01">
        <div className="row" style={{ height: "100%" }}>
          <div className="col-12">
            <div className="outlinewrap">
              <div className="nav">
                <BreadCrumb context={breadCrumbProp} />
              </div>
              <div className="job-logo">
                <div className="row justify-content-between">
                  <div className="col-lg-6">
                    <div className="d-flex gap-3">
                      <div className="logo-image">
                        <img
                          src={job?.recruiter?.company_logo?.href_link ?? ""}
                          alt=""
                        />
                      </div>
                      <div className="job-title">
                        <div style={{ display: 'flex', gap: '5px' }}>
                          <h4 className="mb-1">{job?.title ?? "No Title"}</h4>
                          <img src={job?.isJobApproved ? "/img/svg/approved.svg" : '/img/svg/pending.svg'} alt="" style={{ width: '20px' }} />
                        </div>
                        <h3>{job?.company_name ?? "No Company Name"}</h3>
                        <p className="my-1">
                          {job?.location?.country ?? "No Country"},{" "}
                          {job?.location?.state ?? "No State"}
                        </p>
                        <p>{job?.job_type ?? "No Job Type"}</p>
                        <p className="company-link">
                          {job?.company_link ?? "No Company Link"}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="d-flex gap-2 justify-content-end">
                      <FormControl>
                        <Select
                          value={age}
                          onChange={(event) => handleChange(event, job?._id)}
                          displayEmpty
                          inputProps={{ "aria-label": "Without label" }}
                          className={`muiSelect ${age}`}
                        >
                          <MenuItem value={"pending"}>Pending</MenuItem>
                          <MenuItem value={"approved"}>Approved</MenuItem>
                          <MenuItem value={"declined"}>Declined</MenuItem>
                        </Select>
                      </FormControl>
                      <Button
                        text={`See Applicants (${jobApplicants})`}
                        primaryNull={true}
                        small={true}
                        disabled={jobApplicants === 0}
                        onClick={() => navigate(`/admin/jobdetails/applicants/${job._id}`)}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="job-description ">
                <div className="d-flex mt-3 mb-4 align-items-center gap-3">
                  <div style={{ width: "20px", height: "20px" }}>
                    <img src="/img/svg/toolkit.svg" alt="" />
                  </div>
                  <h3 className="section-name">Job Description</h3>
                </div>
                <p>
                  {job?.description !== "" ? (
                    job.description
                  ) : (
                    <div className="text-center d-flex flex-column gap-3 m-auto py-3 align-items-center border-bottom">
                      <img
                        src={"/img/svg/noexp.svg"}
                        alt="no experience"
                        style={{ width: "70px" }}
                      />
                      <h5 className="fw-bold">No Description</h5>
                    </div>
                  )}
                </p>
              </div>
              <div className="expected-pay">
                <div className="d-flex mt-3 mb-4 align-items-center gap-3">
                  <div style={{ width: "20px", height: "20px" }}>
                    <img src="/img/svg/circledollar.svg" alt="" />
                  </div>
                  <h3 className="section-name">Expected Pay</h3>
                </div>
                <h2 className="expected_pay_currency">
                  {job?.currency ?? "$"} {job?.expected_pay ?? "0"}
                </h2>
              </div>
              <div className="skills_requried">
                <div className="d-flex mt-3 mb-4 align-items-center gap-3">
                  <div style={{ width: "20px", height: "20px" }}>
                    <img src="/img/svg/circledollar.svg" alt="" />
                  </div>
                  <h3 className="section-name">Skills Required</h3>
                </div>
                <ul>
                  {job?.skill_names_list?.map((skill, index) => (
                    <li key={index} className="skill_list">
                      {skill}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ApplyReasonModal
        openAlert={openAlert}
        open={isDeclineModalOpen}
        handleClose={handleCloseDeclineModal}
        handleOpenAlert={handleOpenAlert}
        handleCloseAlert={() => setOpenAlert(false)}
        textValue={declineReason}
        handleChange={(e) => setDeclineReason(e.target.value)}
        handleDecline={handleDecline}
      />
    </div>
  );
};

export default JobDetail;
