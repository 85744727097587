import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Textarea from "../../textareaField/Textarea";
import "../modal.css";
import Button from "@/component/buttons/Button";
import Modalheader from "../Modalheader";
import AlertModal from "../AlertModal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const ApplyReasonModal = ({
  open,
  handleClose,
  openAlert,
  handleCloseAlert,
  handleDecline,
  handleOpenAlert,
  textValue,
  handleChange,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"Admin Modal"} handleClose={handleClose} />
          <div className="modal_body p-5">
            <div className="tag">Declined</div>
            <h4 className="modal_title my-3">Reasons for Decline</h4>
            <div>
              <Textarea
                id="1"
                value={textValue}
                onChange={handleChange}
                rows={9}
                placeholder="Enter reasons for decline"
                maxLength={100}
              />
            </div>
            <div className="d-flex mt-4 gap-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={handleClose}
              />
              <Button
                text="Decline"
                primaryNull={false}
                small={true}
                onClick={handleOpenAlert}
              />
            </div>
          </div>
        </Box>
      </Modal>
      <AlertModal handleDecline={handleDecline} handleCloseAlert={handleCloseAlert} openAlert={openAlert} />
    </div>
  );
};

export default ApplyReasonModal;
