/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import Button from "@/component/buttons/Button";
import Topbar from "./components/topbar/Topbar";
import api from "@/services/api";
import Loading from "./components/loading/loading";
import InputField from "./components/inputField/InputField";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Checkbox } from "@mui/material";
import OverViewTable from "./overviewTable/OverViewTable";
// import CsvDownloadButton from 'react-json-to-csv'
import { baseUrl } from "../../services/routes";
import { downloadFile } from "./components/downloadFile";

const Dashboardreview = ({ name }) => {
  const [totalTalents, setTotalTalents] = useState("");
  const [totalJobs, setTotalJobs] = useState("");
  const [totalRecruiters, setTotalRecruiters] = useState("");;
  const [loading, setLoading] = useState(false);
  const [userActivities, setUserActivities] = useState([])
  const [totalTrails, setTotalTrails] = useState('')
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [filterOptions, setFilterOptions] = useState({
    startDate: "",
    endDate: "",
    user: "",
  });

  const fetchData = async () => {
    setLoading(true);
    try {
      const filters = {};
      if (filterOptions.startDate) {
        filters.startDate = filterOptions.startDate;
      }
      if (filterOptions.endDate) {
        filters.endDate = filterOptions.endDate;
      }
      if (filterOptions.user) {
        filters.user = filterOptions.user;
      }
      const userActivities = await api.getTrail({...filters, pageNumber: currentPage, pageSize: itemsPerPage })
      api.getTrailOverview().then(res=>{
        setTotalTalents(res.data?.totalTalents || 0);
        setTotalJobs(res.data?.totalJobs || 0);
        setTotalRecruiters(
          res.data?.totalRecruiters || 0
        );
      }).catch(error =>console.log(error))
      setUserActivities(userActivities.data?.trails)
      setTotalTrails(userActivities.data?.totalTrails)

    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };


  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [filterOptions, currentPage, itemsPerPage]);

  const totalNumberOfPages = Math.ceil(totalTrails / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const exportTrails = () =>{
    const url = baseUrl + '/audit-trail/export'
    downloadFile(url,`auditTrails.csv`)
  }

  return (
    <div className="col-lg-10 col-12 py-3 ">
      <div className="row gap-4">
        <div className="col-12">
          <Topbar
            name={`Hi ${name}`}
            briefInfo={"Here is a brief overview of the activities on Frontters"}
            boxes={[
              {
                number: loading ? <Loading /> : `${totalTalents}`,
                label: "Talents",
                backgroundcolor: false,
              },
              {
                number: loading ? <Loading /> : `${totalJobs}`,
                label: "Jobs",
                backgroundcolor: true,
              },
              {
                number: loading ? <Loading /> : `${totalRecruiters}`,
                label: "Recruiters",
                backgroundcolor: false,
              },
            ]}
            isBox={true}
            className={'bottom_radius'}
          />
        </div>
        <div className="col-12">
          <div className="table-container top_radius ">
            <div className="header_con my-3">
              <h4>Event Logs</h4>

              <div className="export_filter my-4">
                <div className="row justify-content-between align-items-center">
                  <div className="col-lg-8">
                    <div className="row gap-3">
                      <div className="col-lg-4">
                        <InputField title={"Export From"} type={"date"} value={filterOptions.startDate} onChange={(e) => {
                          setFilterOptions((prevOptions) => ({
                            ...prevOptions,
                            startDate: e.target.value,
                          }));
                        }} />
                      </div>
                      <div className="col-lg-4">
                        <InputField title={"Export To"} type={"date"} value={filterOptions.endDate}
                          onChange={(e) => {
                            setFilterOptions((prevOptions) => ({
                              ...prevOptions,
                              endDate: e.target.value,
                            }));
                          }} />
                      </div>
                      <div className="col-lg-3">
                        <FormControlLabel
                          value="end"
                          control={
                            <Checkbox
                              sx={{
                                fontSize: "20px",
                                color: "#121212",
                                "&.Mui-checked": {
                                  color: "#CC5600",
                                },
                              }}
                            />
                          }
                          label="Export all"
                          labelPlacement="end"
                          sx={{
                            fontSize: "90px",
                            color: "#121212",
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 d-flex justify-content-end">
                      <Button text="Export" primaryNull={false} small={true} onClick={exportTrails}/>
                  </div>
                </div>
              </div>
            </div>
            <div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : <OverViewTable goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} totalNumberOfPages={totalNumberOfPages} currentPage={currentPage} setCurrentPage={setCurrentPage} rows={userActivities} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboardreview;
