import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import { useState } from "react";
import Button from "@/component/buttons/Button";
import { formatDate } from "../../../../component/formatter";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Pagination from "../../../dashboardreview/components/pagination/Pagination";



function Row({ row }) {
  const [age, setAge] = useState("pending");
  const navigate = useNavigate();

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell className="custom-cell">{row.job.title}</TableCell>
        <TableCell className="custom-cell">{row.job.recruiter.first_name + ' ' + row.job.recruiter.last_name}</TableCell>
        <TableCell className="custom-cell">{formatDate(row.createdAt)}</TableCell>
        <TableCell className="custom-cell">{row.applicants}</TableCell>
        <TableCell className="custom-cell">
          <FormControl>
            <Select
              value={age}
              onChange={handleChange}
              displayEmpty
              inputProps={{ "aria-label": "Without label" }}
              className={`muiSelect ${age}`}
            >
              <MenuItem value={"pending"}>Pending</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"declined"}>Declined</MenuItem>
            </Select>
          </FormControl>
        </TableCell>
        <TableCell className="custom-cell">
          <div className="d-flex gap-3 col-lg-3">
            <div>
              <Button text="View" primaryNull={true} small={true} onClick={() => navigate(`/admin/jobdetails/${row._id}`)} />
            </div>
            <div>
              <div className="move_dots">
                <span className="mb-1">...</span>
              </div>
            </div>
          </div>
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function AppliedJobListingTable({ jobs, loading }) {

  const itemsPerPage = 4;
  const [currentPage, setCurrentPage] = useState(1)

  const totalNumberOfPages = Math.ceil(jobs.length / itemsPerPage)
  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1)
    }
  }

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1)
    }
  }
  return (
    <TableContainer>
      <Table aria-label="JobListing table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Position</TableCell>
            <TableCell>Recruiter</TableCell>
            <TableCell>Date Created</TableCell>
            <TableCell>Applicants</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {jobs.map((job, index) => {
            return <Row row={job} key={index} />;
          })}
        </TableBody>
      </Table>
      <Pagination goToNextPage={goToNextPage} goToPreviousPage={goToPreviousPage} currentPage={currentPage} setCurrentPage={setCurrentPage} totalNumberOfPages={totalNumberOfPages} />
    </TableContainer>
  );
}
