import React, { useEffect, useState, useCallback } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import "./css/SignUp.css";
import Button from "../../component/buttons/Button";
import { useNavigate } from "react-router-dom";
import { auth } from "../../services/api/auth";
import service from "../../services";

const SignUp = ({ isLogin }) => {
 const navigate = useNavigate();
 const [loading, setLoading] = useState(false);
 const [isPasswordVisible, setPasswordVisibility] = useState(false);
 const togglePasswordVisibility = () => {
  setPasswordVisibility(!isPasswordVisible);
 };
 const initialValues = {
  email: "",
  password: "",
 };

 const authChecker = useCallback(() => {
  if (service.getTokensLocalStorage() === null) {
   isLogin(false);
  }
 }, [isLogin]);

 useEffect(() => {
  authChecker();
 }, [authChecker]);

 // const authChecker = async () => {
 //     const token = service.getTokensLocalStorage()
 //     if (token === null) {
 //         await isLogin(false)
 //     };
 // };

 const onSubmit = (values) => {
  setLoading(true);
  auth
   .login(values)
   .then((res) => {
    console.log(res)
    if (res.status === 201) {
     const newToken = {
      access: {
       token: res.data.access_token,
      },
      refresh: {
       token: res.data.refresh_token,
      },
     };

     service.setTokensLocalStorage(newToken);
    }
    navigate("/admin/dashboard");
     service.reloadPage();
   })
   .catch((err) => {
    alert(err.data?.message);
   })
   .finally(() => {
    setLoading(false);
   });
 };

 const validate = (values) => {
  let errors = {};

  if (!values.email) {
   errors.email = "Email is Required";
  } else if (
   !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(values.email)
  ) {
   errors.email = "Invalid Email Contact";
  }

  if (!values.password) {
   errors.password = "Password is Required";
  }

  return errors;
 };

 return (
  <Formik initialValues={initialValues} onSubmit={onSubmit} validate={validate}>
   <div className="container overall-container">
    <h1>Frontter is all about you</h1>
    <Form className="form-contanier my-5">
     <div className="text-container my-4">
      <h3 className="my-2">Sign in as an Admin</h3>
     </div>
     <div className="row  ">
      <div className="col-sm-6 col-12 my-4">
       <label htmlFor="email">Email</label>
       <div className="input-container">
        <Field
         type="email"
         name="email"
         id="email"
         placeholder="Enter Your Email"
        />
       </div>
       <ErrorMessage name="email" component={"p"} className="error" />
      </div>
      <div className="col-sm-6 col-12 my-4">
       <label htmlFor="password">Password</label>
       <div className="input-container d-flex align-items-center">
        <Field
         type={isPasswordVisible ? "text" : "password"}
         name="password"
         id="password"
         placeholder="Enter Your Password"
        />
        <div className="eye" onClick={togglePasswordVisibility}>
         {isPasswordVisible ? (
          <img src="/img/svg/eye-close.svg" alt="Close Eye" />
         ) : (
          <img src="/img/svg/eye-open.svg" alt="Open Eye" />
         )}
        </div>
       </div>
       <ErrorMessage name="password" component={"p"} className="error" />
      </div>

      <div className="mt-3">
       <Button
        text={
         loading ? (
          <>
           <div className="text-center" style={{ padding: "0 25px" }}>
            <div className="spinner-border spinner-border-sm" role="status">
             <span className="visually-hidden">Loading...</span>
            </div>
           </div>
          </>
         ) : (
          <>
           <h2>Sign In</h2>
           <i className="fa-solid fa-arrow-right"></i>
          </>
         )
        }
        buttonType="submit"
        secondary={false}
        small={true}
       />
      </div>
     </div>
    </Form>
   </div>
  </Formik>
 );
};

export default SignUp;
