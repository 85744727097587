import React from "react";
import "./css/Pagination.css";

const Pagination = ({
  goToPreviousPage,
  currentPage,
  setCurrentPage,
  totalNumberOfPages,
  goToNextPage,
}) => {
  const maxPageNumbersToShow = 5;
  let startPage, endPage;

  if (totalNumberOfPages <= maxPageNumbersToShow) {
    // Total pages less than max, so show all pages
    startPage = 1;
    endPage = totalNumberOfPages;
  } else {
    // More than maxPageNumbersToShow pages, so calculate start and end pages
    if (currentPage <= 3) {
      startPage = 1;
      endPage = maxPageNumbersToShow;
    } else if (currentPage + 2 >= totalNumberOfPages) {
      startPage = totalNumberOfPages - maxPageNumbersToShow + 1;
      endPage = totalNumberOfPages;
    } else {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
    }
  }

  return (
    <div className="footer">
      <nav aria-label="Page navigation example">
        <ul className="pagination justify-content-center gap-3">
          <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
            <button
              className="page-link arrow"
              onClick={goToPreviousPage}
              disabled={currentPage === 1}
            >
              <img
                src="/img/svg/pagearrowleft.svg"
                alt=""
                className="img-arrow"
              />
            </button>
          </li>
          {startPage > 1 && (
            <>
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => {
                    setCurrentPage(1);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  1
                </button>
              </li>
              {startPage > 2 && (
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
              )}
            </>
          )}
          {Array.from({ length: endPage - startPage + 1 }, (_, index) => {
            const pageNumber = startPage + index;
            return (
              <li
                key={pageNumber}
                className={`page-item ${
                  currentPage === pageNumber ? "active" : ""
                }`}
              >
                <button
                  className={`page-link ${
                    currentPage === pageNumber ? "activepage" : ""
                  }`}
                  onClick={() => {
                    setCurrentPage(pageNumber);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  {pageNumber}
                </button>
              </li>
            );
          })}
          {endPage < totalNumberOfPages && (
            <>
              {endPage < totalNumberOfPages - 1 && (
                <li className="page-item disabled">
                  <span className="page-link">...</span>
                </li>
              )}
              <li className="page-item">
                <button
                  className="page-link"
                  onClick={() => {
                    setCurrentPage(totalNumberOfPages);
                    window.scrollTo({ top: 0, behavior: "smooth" });
                  }}
                >
                  {totalNumberOfPages}
                </button>
              </li>
            </>
          )}
          <li
            className={`page-item ${
              currentPage === totalNumberOfPages ? "disabled" : ""
            }`}
          >
            <button
              className="page-link arrow"
              onClick={goToNextPage}
              disabled={currentPage === totalNumberOfPages}
            >
              <img
                src="/img/svg/pagearrowright.svg"
                className="img-arrow"
                alt=""
              />
            </button>
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Pagination;
