import React, { useEffect, useState } from "react";
import BreadCrumb from "../../../component/bread-crumb/BreadCrumb";
import Button from "../../../component/buttons/Button";
import Loading from "../../dashboardreview/components/loading/loading";
import { Box, TextField } from "@mui/material";
import api from "../../../services/api";

const CustomMessages = () => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchMessages();
  }, []);

  const fetchMessages = () => {
    setLoading(true);
    api
      .getCustomMessages()
      .then((res) => {
        setMessages(res.data);
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const handleMessageDelete = (id) => {
    setLoading(true);
    api
      .deleteCustomMessages(id)
      .then((res) => {
        if (res.status === 200) {
          setMessages((prevMessage) =>
            prevMessage.filter((message) => message._id !== id)
          );
        }
        setLoading(false);
      })
      .catch((res) => console.log(res));
  };

  const postMessage = () => {
    setLoading(true);
    const data = {
      text: message,
    };
    api
      .createCustomMessage(data)
      .then((res) => {
        if (res.status === 201) {
          setMessage("");
          fetchMessages(); 
        }
      })
      .catch(console.error)
      .finally(() => setLoading(false));
  };

  const breadCrumbProp = [
    {
      title: "Chatroom",
      link: "",
      previous: true,
    },
    {
      title: "Custom Message",
      link: "",
    },
  ];

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <div style={{ backgroundColor: "white", padding: "16px" }}>
            <h2 className="username">Custom Messages</h2>
          </div>
          <div className="table-container" style={{ height: "80vh" }}>
            <BreadCrumb context={breadCrumbProp} />
            <Box
              display="flex"
              flexDirection="column"
              gap="5px"
              p="16px"
              maxWidth="400px"
            >
              <label htmlFor="question">Enter Question here</label>
              <TextField
                id="question"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                variant="outlined"
                placeholder="Enter Question here"
                InputProps={{
                  sx: {
                    padding: "5px",
                    height: "auto",
                  },
                }}
              />
              <Button onClick={postMessage} small text="Save" />
            </Box>
            <div>
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <div className="d-flex flex-wrap gap-2">
                  {messages.map((x) => {
                    return (
                      <div
                        key={x._id}
                        className="text-wrap rounded-2 px-3 py-2 d-flex justify-content-between align-items-center"
                        style={{
                          width: "auto",
                          color: "#CC5600",
                          border: "1px solid #CC5600",
                        }}
                      >
                        <p
                          style={{ color: "#CC5600", fontWeight: 700 }}
                          className="m-0"
                        >
                          {x.text}
                        </p>
                        <button
                          type="button"
                          style={{ color: "#CC5600", fontWeight: 700 }}
                          className="ms-2"
                          onClick={() => handleMessageDelete(x._id)}
                        >
                          {"x"}
                        </button>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomMessages;
