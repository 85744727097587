import React from "react";
import { useState } from "react";
import "./css/Sidebar.css";
import { NavLink } from "react-router-dom";

const Sidebar = () => {
  const [collapsed, setCollapsed] = useState(true);

  const [sidebarItems, setSidebarItems] = useState([
    {
      id: 1,
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M15.9078 11.9175C15.4307 13.0459 14.6844 14.0402 13.7342 14.8135C12.784 15.5868 11.6589 16.1156 10.4571 16.3536C9.25538 16.5916 8.01362 16.5316 6.84041 16.1788C5.6672 15.8261 4.59827 15.1913 3.72707 14.33C2.85586 13.4687 2.20891 12.4071 1.84278 11.238C1.47664 10.0689 1.40247 8.82787 1.62675 7.62348C1.85103 6.41909 2.36692 5.28797 3.12934 4.32902C3.89175 3.37007 4.87746 2.61249 6.0003 2.1225M16.5003 9C16.5003 8.01509 16.3063 7.03982 15.9294 6.12987C15.5525 5.21993 15 4.39314 14.3036 3.6967C13.6072 3.00026 12.7804 2.44781 11.8704 2.0709C10.9605 1.69399 9.98522 1.5 9.0003 1.5V9H16.5003Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      text: "Overview",
      active: false,
      linkto: "/admin/dashboard",
    },
    {
      id: 7,
      svg: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="18"
          height="18"
          viewBox="0 0 18 18"
          fill="none"
        >
          <path
            d="M12 15.75V3.75C12 3.35218 11.842 2.97064 11.5607 2.68934C11.2794 2.40804 10.8978 2.25 10.5 2.25H7.5C7.10218 2.25 6.72064 2.40804 6.43934 2.68934C6.15804 2.97064 6 3.35218 6 3.75V15.75M3 5.25H15C15.8284 5.25 16.5 5.92157 16.5 6.75V14.25C16.5 15.0784 15.8284 15.75 15 15.75H3C2.17157 15.75 1.5 15.0784 1.5 14.25V6.75C1.5 5.92157 2.17157 5.25 3 5.25Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      text: "Job Listing",
      active: false,
      linkto: "/admin/jobListing",
    },
    {
      id: 2,
      svg: (
        <svg
          width="18"
          height="16"
          viewBox="0 0 18 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12.75 14.75V13.25C12.75 12.4544 12.4339 11.6913 11.8713 11.1287C11.3087 10.5661 10.5456 10.25 9.75 10.25H3.75C2.95435 10.25 2.19129 10.5661 1.62868 11.1287C1.06607 11.6913 0.75 12.4544 0.75 13.25V14.75M17.25 14.75V13.25C17.2495 12.5853 17.0283 11.9396 16.621 11.4142C16.2138 10.8889 15.6436 10.5137 15 10.3475M12 1.3475C12.6453 1.51273 13.2173 1.88803 13.6257 2.41423C14.0342 2.94044 14.2559 3.58762 14.2559 4.25375C14.2559 4.91988 14.0342 5.56706 13.6257 6.09327C13.2173 6.61947 12.6453 6.99477 12 7.16M9.75 4.25C9.75 5.90685 8.40685 7.25 6.75 7.25C5.09315 7.25 3.75 5.90685 3.75 4.25C3.75 2.59315 5.09315 1.25 6.75 1.25C8.40685 1.25 9.75 2.59315 9.75 4.25Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      text: "Users",
      active: false,
      children: [
        {
          id: 3,
          text: "Talents",
          active: false,
          linkto: "/admin/talents",
        },
        {
          id: 4,
          text: "Recruiters",
          active: false,
          linkto: "/admin/recruiters",
        },
        {
          id: 5,
          text: "Admin",
          active: false,
          linkto: "/admin/admins",
        },
        {
          id: 6,
          text: "Super Admin",
          active: false,
          linkto: "/admin/super-admins",
        },
      ],
    },
    {
      id: 8,
      svg: (
        <svg
          width="18"
          height="18"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M14.75 7.62502C14.7526 8.61492 14.5213 9.59144 14.075 10.475C13.5458 11.5338 12.7323 12.4244 11.7256 13.047C10.7189 13.6696 9.55868 13.9996 8.375 14C7.3851 14.0026 6.40859 13.7713 5.525 13.325L1.25 14.75L2.675 10.475C2.2287 9.59144 1.99742 8.61492 2 7.62502C2.00046 6.44134 2.33046 5.28116 2.95304 4.27443C3.57562 3.26771 4.46619 2.4542 5.525 1.92502C6.40859 1.47872 7.3851 1.24744 8.375 1.25002H8.75C10.3133 1.33627 11.7898 1.99609 12.8969 3.10317C14.0039 4.21024 14.6638 5.68676 14.75 7.25002V7.62502Z"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      ),
      text: "FeedBack",
      active: false,
      linkto: "/admin/feedback",
    },
    {
      id: 9,
      svg: (
        <svg width="18" height="16" viewBox="0 0 18 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M8.99983 5.99991V8.99991M8.99983 11.9999H9.00733M7.71733 2.14491L1.36483 12.7499C1.23386 12.9767 1.16456 13.2339 1.16382 13.4958C1.16309 13.7577 1.23095 14.0153 1.36065 14.2428C1.49035 14.4704 1.67738 14.66 1.90311 14.7928C2.12885 14.9256 2.38543 14.997 2.64733 14.9999H15.3523C15.6142 14.997 15.8708 14.9256 16.0966 14.7928C16.3223 14.66 16.5093 14.4704 16.639 14.2428C16.7687 14.0153 16.8366 13.7577 16.8358 13.4958C16.8351 13.2339 16.7658 12.9767 16.6348 12.7499L10.2823 2.14491C10.1486 1.92449 9.96037 1.74225 9.73573 1.61577C9.51108 1.4893 9.25763 1.42285 8.99983 1.42285C8.74203 1.42285 8.48858 1.4893 8.26394 1.61577C8.03929 1.74225 7.85104 1.92449 7.71733 2.14491Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      text: "Report",
      active: false,
      linkto: "/admin/reports",
    },
    {
      id: 10,
      svg: (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.025 4.7248C10.8876 4.865 10.8106 5.05349 10.8106 5.2498C10.8106 5.44612 10.8876 5.63461 11.025 5.7748L12.225 6.9748C12.3652 7.11222 12.5537 7.18919 12.75 7.18919C12.9463 7.18919 13.1348 7.11222 13.275 6.9748L16.1025 4.1473C16.4796 4.98069 16.5938 5.90923 16.4299 6.80916C16.2659 7.70909 15.8315 8.53769 15.1847 9.18451C14.5379 9.83134 13.7093 10.2657 12.8094 10.4296C11.9094 10.5936 10.9809 10.4794 10.1475 10.1023L4.96501 15.2848C4.66664 15.5832 4.26197 15.7508 3.84001 15.7508C3.41806 15.7508 3.01338 15.5832 2.71501 15.2848C2.41665 14.9864 2.24902 14.5818 2.24902 14.1598C2.24902 13.7378 2.41665 13.3332 2.71501 13.0348L7.89751 7.8523C7.52039 7.01891 7.4062 6.09038 7.57017 5.19044C7.73414 4.29051 8.16848 3.46192 8.8153 2.81509C9.46213 2.16827 10.2907 1.73393 11.1907 1.56996C12.0906 1.40599 13.0191 1.52018 13.8525 1.8973L11.0325 4.7173L11.025 4.7248Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      text: "Skills",
      active: false,
      linkto: "/admin/skills",
    },
    {
      id: 11,
      svg: (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21 15C21 15.5304 20.7893 16.0391 20.4142 16.4142C20.0391 16.7893 19.5304 17 19 17H7L3 21V5C3 4.46957 3.21071 3.96086 3.58579 3.58579C3.96086 3.21071 4.46957 3 5 3H19C19.5304 3 20.0391 3.21071 20.4142 3.58579C20.7893 3.96086 21 4.46957 21 5V15Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
      ),
      text: "Conversations",
      active: false,
      linkto: "/admin/conversations",
    },
    {
      id: 12,
      svg: (
        <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
          <g clip-path="url(#clip0_352_770)">
            <path d="M9 4.5V9L12 10.5M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </g>
          <defs>
            <clipPath id="clip0_352_770">
              <rect width="18" height="18" fill="white" />
            </clipPath>
          </defs>
        </svg>

      ),
      text: "Updates",
      active: false,
      linkto: "/admin/updates",
    },

    // Add more objects as needed
  ]);
  const handleItemClick = (item) => {
    setSidebarItems((prevItems) =>
      prevItems.map((prevItem) => ({
        ...prevItem,
        active: prevItem.id === item.id,
      }))
    );
    if (item.children) {
      setCollapsed(!collapsed);
    }
  };


  return (
    <div className="stylewidth col-lg-1 col-12 py-4 d-lg-block d-none">
      <div className="contanier-sidebar">
        <div className="contanier-sidebar-items">
          {sidebarItems.map((item) => (
            <div key={item.id}>
              <NavLink
                to={item.linkto}
                className={`sidenav-title ${item.active ? "activeitem" : "notactive"}`}
                onClick={() => handleItemClick(item)}
              >
                {item.svg && <div className="sidenav-icon">{item.svg}</div>}
                <div className="side-text">{item.text}</div>
              </NavLink>
              {!collapsed && item.children && (
                <div
                  className="d-flex flex-column justify-content-center"
                  style={{ margin: "10px 0 0 10px", gap: "8px" }}
                >
                  {item.children.map((childItem) => (
                    <NavLink
                      key={childItem.id}
                      to={childItem.linkto}
                      className={`sidenav-subtitle d-flex align-items-center gap-3 ${childItem.active ? "activeitem" : "notactive"}`}
                      onClick={() => handleItemClick(childItem)}
                    >
                      <div className="side-text" style={{ color: "#CC5600" }}>{childItem.text}</div>
                    </NavLink>
                  ))}
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>

  );
};

export default Sidebar;
