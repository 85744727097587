import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Fragment } from "react";
import { formatDate } from "../../../component/formatter";
import Pagination from "../components/pagination/Pagination";

function Row({ row }) {
  return (
    <Fragment>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell component="th" scope="row" className="custom-cell">
          {formatDate(row.createdAt)}
        </TableCell>
        <TableCell
          className="custom-cell"
          style={{
            overflow: "hidden",
            textOverflow: "ellipsis",
            maxWidth: "100px",
            whiteSpace: "nowrap",
          }}
        >
          {row.user}
        </TableCell>
        <TableCell
          className="custom-cell"
          style={{
            overflow: "hidden",
            maxWidth: "120px",
          }}
        >
          <p style={{ backgroundColor: row.userType === 'Admin' ? '#219653' : row.userType === 'Super Admin' ? 'black' : '#FFB700', display: 'inline-block', padding: '5px', textTransform: 'capitalize', borderRadius: '10px', textAlign: 'center', color:'white' }}>{row.userType}</p>
        </TableCell>

        <TableCell
          className="custom-cell"
          style={{
            overflow: "hidden",
            maxWidth: "350px",
          }}
        >
          {row.action}
        </TableCell>
      </TableRow>
    </Fragment>
  );
}

export default function OverViewTable({ rows, goToNextPage, goToPreviousPage, currentPage, setCurrentPage, totalNumberOfPages }) {
  return (
    <TableContainer>
      <Table aria-label="JobListing table" sx={{ border: "none" }}>
        <TableHead>
          <TableRow className="custom-row">
            <TableCell>Activity Date</TableCell>
            <TableCell>User</TableCell>
            <TableCell>Role</TableCell>
            <TableCell>Activity</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, index) => {
            return <Row row={row} key={index} />;
          })}
        </TableBody>
      </Table>
      <Pagination
        goToNextPage={goToNextPage}
        goToPreviousPage={goToPreviousPage}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        totalNumberOfPages={totalNumberOfPages}
      />
    </TableContainer>
  );
}
