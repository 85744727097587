import axios from 'axios';
import routes from '../routes';

export const auth = {
	register: async (postBody) => {
		return await axios.post(routes.register(), postBody);
	},
	login: async (authBody) => {
		return await axios.post(routes.login(), authBody);
	},
	
};
