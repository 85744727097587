import {
  Checkbox,
  FormControl,
  FormControlLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import api from "../../../services/api";
import "./AdminProfile.css";
import { CopyAll } from "@mui/icons-material";
import Button from "@/component/buttons/Button";
import InputField from "../components/inputField/InputField";
import ActivityTable from "./ActivityTable/Activitytable";
import BreadCrumb from "../../../component/bread-crumb/BreadCrumb";
import { baseUrl } from "../../../services/routes";
import { downloadFile } from "../components/downloadFile";



const AdminProfile = () => {
  const { id } = useParams();
  const [admin, setAdmin] = useState({})
  const [name, setName] = useState('')
  const [activities, setActivities] = useState([])
  // eslint-disable-next-line
  const [isLoading, setIsLoading] = useState(false);
  const [age, setAge] = useState("");

  const breadCrumbProp = [
    {
      title: "Users",
      link: "",
      previous: true,
    },
    {
      title: "Profile",
      link: "",
    },
  ];
  // eslint-disable-next-line
  const [filterOptions, setFilterOptions] = useState({
    startDate: "",
    endDate: "",
    user: "",
  });

  useEffect(() => {
    api.adminById(id).then(res => setAdmin(res.data)).catch((res) => console.log(res))
    setName(admin.firstName + " " + admin.lastName)
    api.getTrail({ user: name }).then(res => setActivities(res.data?.trails)).catch((res) => console.log(res))
  }, [id, admin.firstName, admin.lastName, name])

  const handleChange = (event, id) => {
    const status = event.target.value;
    setAge(status);
    if (status === "to_super") {
      api
        .makeAdmin(id, "super")
        .then((res) => console.log(res))
        .catch((res) => console.log(res));
    } else {
      api
        .makeAdmin(id, "local")
        .then((res) => console.log(res))
        .catch((res) => console.log(res));
    }
  };

  const handleCopy = (value, item) => {
    navigator.clipboard.writeText(value)
      .then(() => {
        alert(`${item} copied to clipboard!`);
      })
      .catch(err => {
        console.error("Could not copy text: ", err);
      });
  };

  const exportAdminTrails = () => {
    const url = baseUrl + '/audit-trail/export'
    downloadFile(url, `${name}.csv`)
  }

  return (
    <div
      id="Recruiter_View_Talent_Profile_Main_Container"
      className="col-lg-10 col-12"
    >
      <div>
        <div className="container-01 py-4">
          <div className="row ">
            <BreadCrumb context={breadCrumbProp} />
            <div className="first-col col-12 mb-5 mb-lg-0">
              <div className="outline-wrap" style={{ marginBottom: "40px" }}>
                <div
                  className="section  py-4 px-3"
                  style={{
                    border: "4px solid #000000",
                    borderRadius: "10px",
                  }}
                >
                  {!isLoading && (
                    <div className="section-a ">
                      <div className="row justify-content-between w-100 align-items-center ">
                        <div
                          className="col-lg-7 side_profile"
                          style={{
                            border: "none",
                          }}
                        >
                          <div className="d-flex align-items-center gap-3  mb-lg-0 w-100">
                            <div className="recruiter_img_con">
                              <div className="user-img ">
                                <img src={admin.profile_picture?.href_link} alt="my profile" />
                              </div>
                            </div>
                            <div className="user-context">
                              <div>
                                <div className="d-flex align-items-center gap-3">
                                  <h1 className="admin_name">{admin.firstName + " " + admin.lastName}</h1>
                                  <span className="role_tag" style={{ backgroundColor: admin.role === 'Admin' ? '#219653' : 'black' }}>{admin.role}</span>
                                </div>
                                <div className="admin_contact d-flex aligns-items-center gap-3 mt-2" style={{ cursor: 'pointer' }}>
                                  <p className="mb-0 admin_email">
                                    <CopyAll
                                      sx={{
                                        color: "#CC5600",
                                      }}
                                      onClick={() => handleCopy(admin.email, 'Email')}
                                    />
                                    {admin.email}
                                  </p>
                                  <p className="admin_number">
                                    {" "}
                                    <CopyAll
                                      sx={{
                                        color: "#CC5600",
                                      }}
                                      onClick={() => handleCopy('+2348023424242', 'Phone Number')}
                                    />
                                    +2348023424242
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <FormControl>
                            <Select
                              value={age}
                              onChange={(event) => handleChange(event, 1)}
                              displayEmpty
                              inputProps={{ "aria-label": "Without label" }}
                              className={`muiSelect ${age}`}
                            >
                              <MenuItem value={""}>Change Priviledge</MenuItem>
                              {admin.role === "Super Admin" && <MenuItem value={"to_local"}>Downgrade to Local admin</MenuItem>}
                              {admin.role === 'Admin' && <MenuItem value={"to_super"}>Upgrade to Super admin</MenuItem>}
                            </Select>
                          </FormControl>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="admin_activity my-3 py-3">
                    <h2>Activity</h2>
                    <div className="export_filter my-4">
                      <div className="row justify-content-between align-items-center">
                        <div className="col-lg-8">
                          <div className="row gap-3">
                            <div className="col-lg-4">
                              <InputField
                                title={"Activity from"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={() => console.log("i")}
                              />
                            </div>
                            <div className="col-lg-4">
                              <InputField
                                title={"Activity To"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={() => console.log("i")}
                              />
                            </div>
                            <div className="col-lg-3">
                              <FormControlLabel
                                value="end"
                                control={
                                  <Checkbox
                                    sx={{
                                      fontSize: "20px",
                                      color: "#121212",
                                      "&.Mui-checked": {
                                        color: "#CC5600",
                                      },
                                    }}
                                  />
                                }
                                label="Export all"
                                labelPlacement="end"
                                sx={{
                                  fontSize: "90px",
                                  color: "#121212",
                                }}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-3 d-flex justify-content-end">
                          <Button text="Export" primaryNull={false} small={true} onClick={exportAdminTrails} />
                        </div>
                      </div>
                    </div>

                    <ActivityTable activities={activities} />
                  </div>
                  <div className="con-animated-bg">
                    {isLoading &&
                      [1, 1, 1].map((x, y) => (
                        <div className={"animated-bg"} key={y}></div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminProfile;
