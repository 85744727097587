import React, { useState, useEffect } from "react";
import Topbar from "../components/topbar/Topbar";
import Button from "@/component/buttons/Button";
import UsersListingTable from './usersListingTable/UsersListingTable'
import './css/UserListing.css'
import api from '@/services/api';
import Loading from "../components/loading/loading";

const Users = () => {
  const [combinedData, setCombinedData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [alpha, setAlpha] = useState('asc');
  const [verifiedSortOrder, setVerifiedSortOrder] = useState('asc');
  const [roleSortOrder, setRoleSortOrder] = useState('asc');

  // eslint-disable-next-line
  const [users, setUsers] = useState("Gathering Users....");
  const [loading, setLoading] = useState(false);
  // const [showAllUsers, setShowAllUsers] = useState(false);
  const [search, setSearch] = useState('');

  const fetchData = async () => {
    try {
      setLoading(true);
      const talentsResponse = await api.getTalents();
      const recruitersResponse = await api.getRecuriters();

      const talentsData = talentsResponse.data?.talents || [];
      const recruitersData = recruitersResponse.data?.recruiters || [];

      // Combine talents and recruiters with a 'type' property
      const combinedData = [
        ...talentsData.map((talent) => ({ ...talent, type: "talent" })),
        ...recruitersData.map((recruiter) => ({
          ...recruiter,
          type: "recruiter",
        })),
      ];

      // Sort the combined data based on createdAt
      combinedData.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

      if (combinedData.length === 0) {
        setUsers("No User Available");
      } else {
        setCombinedData(combinedData);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setSearch(searchTerm);
  };

  const verifyTalent = (id) => {
    setLoading(true)
    api.VerifyTalent(id).then((res) => {
      if (res.status === 200) {
        fetchData()
        setLoading(false)
      }
    }).catch((res) => console.log(res))
  }
  const verifyRecruiter = (id) => {
    setLoading(true)
    api.VerifyRecuriter(id).then((res) => {
      if (res.status === 200) {
        fetchData()
        setLoading(false)
      }
    }).catch((res) => console.log(res))
  }

  const handleAlphabeticalSort = () => {
    // Toggle sorting order
    const newSortOrder = alpha === 'asc' ? 'desc' : 'asc';
    setAlpha(newSortOrder);

    // Sort users array based on name
    const sortedUsers = [...combinedData].sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`.toLowerCase();
      const nameB = `${b.first_name} ${b.last_name}`.toLowerCase();
      if (newSortOrder === 'asc') {
        if (nameA < nameB) return -1;
        if (nameA > nameB) return 1;
      } else {
        if (nameA > nameB) return -1;
        if (nameA < nameB) return 1;
      }
      return 0;
    });

    setCombinedData(sortedUsers);
    setIsOpen(false);
  };

  const handleVerifiedSort = () => {
    // Toggle sorting order
    const newSortOrder = verifiedSortOrder === 'asc' ? 'desc' : 'asc';
    setVerifiedSortOrder(newSortOrder);

    // Sort users array based on the isProfileApproved boolean
    const sortedUsers = [...combinedData].sort((a, b) => {
      const isApprovedA = a.isProfileApproved ? 1 : 0;
      const isApprovedB = b.isProfileApproved ? 1 : 0;

      if (newSortOrder === 'asc') {
        return isApprovedA - isApprovedB;
      } else {
        return isApprovedB - isApprovedA;
      }
    });

    setCombinedData(sortedUsers);
    setIsOpen(false);
  };

  const handleRoleSort = () => {
    // Toggle sorting order
    const newSortOrder = roleSortOrder === 'asc' ? 'desc' : 'asc';
    setRoleSortOrder(newSortOrder);

    // Sort users array based on the role
    const sortedUsers = [...combinedData].sort((a) => {
      if (newSortOrder === 'asc') {
        return a.type === 'talent' ? -1 : 1;
      } else {
        return a.type === 'recruiter' ? -1 : 1;
      }
    });

    setCombinedData(sortedUsers);
    setIsOpen(false);
  };





  const filterByName = (data, searchText) => {
    const lowerSearchText = searchText.toLowerCase();
    return data.filter(item => {

      const lowerFirstName = item.first_name.toLowerCase();
      const lowerLastName = item.last_name.toLowerCase();

      return lowerFirstName.includes(lowerSearchText) || lowerLastName.includes(lowerSearchText);
    });
  };
  const filteredUsers = filterByName(combinedData, search)

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <Topbar name={"Users"} />
        </div>
        <div className="col-12">
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between">
                <div className="form d-flex align-items-end   gap-5 col-lg-10">
                  <div className="input-search col-lg-5 d-flex py-2">
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search for users"
                      className="input_text"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                  <div className="input-filter col-lg-5 d-flex py-2">
                    <select className="input_text">
                      <option selected>Filter by</option>
                      <option value="1">One</option>
                      <option value="2">Two</option>
                      <option value="3">Three</option>
                    </select>
                  </div>

                  <div className="d-flex gap-3">
                    <div className="position-relative">
                      <Button text="Sort" primaryNull={true} small={true} onClick={() => setIsOpen(!isOpen)} />
                      {
                        isOpen &&
                        <div className="position-absolute text-white p-2 rounded mt-2" style={{ top: '100%', left: '50%', transform: 'translateX(-50%)', minWidth: '180px', backgroundColor: "#CC5600" }}>
                          <Button text={`Alphabetical ${alpha === 'asc' ? 'A-Z' : 'Z-A'}`} primaryNull={false} small={true} onClick={handleAlphabeticalSort} />
                          <Button text={'By Verification'} primaryNull={false} small={true} onClick={handleVerifiedSort} />
                          <Button text={`By Role ${roleSortOrder === 'asc' ? '(Recruiter)' : '(Talent)'}`} primaryNull={false} small={true} onClick={handleRoleSort} />
                        </div>
                      }
                    </div>
                    <div>
                      <Button text="Export" primaryNull={false} small={true} />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              {loading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                  <Loading />
                </div>) :
                <UsersListingTable jobs={filteredUsers} verifyTalent={verifyTalent} verifyRecruiter={verifyRecruiter} />
              }
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default Users;
