// import React, { useState } from "react";
import Topbar from "../../components/topbar/Topbar";
import BreadCrumb from "@/component/bread-crumb/BreadCrumb";
import "../css/Update.css";
import InputField from "../../components/inputField/InputField";
// import SelectField from "../../components/selectField/SelectField";
// import Textarea from "../../components/textareaField/Textarea";
import { IconButton, Box } from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import Button from "@/component/buttons/Button";
import { Link } from "react-router-dom";

const PreviewUpdate = () => {
  const notes = [
    {
      title: "Bug Fix: Login Page",
      text: "Identified and resolved a critical bug on the login page where users were unable to log in due to an issue with authentication tokens. Implemented a comprehensive solution to refresh tokens upon expiration, ensuring seamless user authentication. Additionally, conducted extensive testing to validate the fix across various devices and browsers to guarantee a smooth user experience under all conditions.",
      noteList: [
        {
          title: "Version Update Notification",
          text: "We are so thrilled to announce the release of our latest version! This update includes several enhancements and bug fixes to improve your experience.",
        },
        {
          title: "What's New",
          text: "1. Added feature X for improved functionality.\n2. Optimized performance for smoother operation.\n3. Fixed minor bugs reported by users.",
        },
        {
          title: "How to Update",
          text: "To update the application, simply visit your device's app store, search for our app, and tap on the 'Update' button. Alternatively, you can enable automatic updates in your device settings to receive updates automatically.",
        },
        {
          title: "Feedback",
          text: "We value your feedback! If you encounter any issues or have suggestions for future updates, please don't hesitate to reach out to us through the app's 'Contact Us' section or via email at support@example.com.",
        },
      ],
    },
  ];

  const breadCrumbProp = [
    {
      title: "Back",
      link: "",
      previous: true,
    },
  ];
  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row">
        <div className="col-12">
          <Topbar name={"Updates"} />
        </div>

        <div className="col-12">
          <div
            className="table-container "
            style={{ borderTop: "1px solid #D4DAE2" }}
          >
            <BreadCrumb context={breadCrumbProp} />

            <div>
              <h2 className="update_title">Preview</h2>
            </div>
            <div className="section_con">
              <div className="my-3">
                <p className="_helper_text my-0">28 August, 2023</p>
                <div className="d-flex justify-content-between">
                  <h4 className="section_con_header my-1">Version 4.5 </h4>
                  <Box>
                    <IconButton
                      sx={{
                        color: "#172D2D",
                      }}
                    >
                      <EditOutlined />
                    </IconButton>
                    <IconButton
                      sx={{
                        color: "#BB363C",
                      }}
                    >
                      <DeleteOutline />
                    </IconButton>
                  </Box>
                </div>
                <p className="text_link">
                  <Link to={"/"} className="learn_more">
                    Learn More
                  </Link>{" "}
                  about the Trulioo Platform
                </p>
              </div>
              <div className="row">
                <div className="col-lg-5">
                  <InputField
                    placeholder={"Examinty Id"}
                    type={"text"}
                    helperText={"This is the link to the Section created"}
                    title={"Shareable Link"}
                    isIconPresent={true}
                    isReadOnly={true}
                  />
                </div>
              </div>

              <>
                {notes.map((note) => {
                  return (
                    <div className="notes my-5">
                      <div className="_notes_header">
                        <h2>{note.title} </h2>
                        <Box>
                          <IconButton
                            sx={{
                              color: "#172D2D",
                            }}
                          >
                            <EditOutlined />
                          </IconButton>
                          <IconButton
                            sx={{
                              color: "#BB363C",
                            }}
                          >
                            <DeleteOutline />
                          </IconButton>
                        </Box>
                      </div>

                      <div className="_notes_body">
                        <p className="my-3 text">
                          {note.text}
                          {note.image && (
                            <div className="note_image col-lg-8 my-3">
                              <img
                                src={note.image}
                                alt=""
                                loading="lazy"
                                width={"100%"}
                              />
                            </div>
                          )}
                        </p>

                        <ul className="note_list">
                          {note.noteList.map((list) => {
                            return (
                              <li>
                                <h3 className="list_title">{list.title}</h3>
                                <p className="list_text">{list.text}</p>
                              </li>
                            );
                          })}

                        </ul>
                      </div>
                    </div>
                  );
                })}
              </>
              <div className="note_action">
                <div className="d-flex gap-3">
                  <div>
                    <Button text="Discard" primaryNull={true} small={true} />
                  </div>
                  <div>
                    <Button
                      text="Create Update"
                      primaryNull={false}
                      small={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreviewUpdate;
