import React from "react";
import { formatDateTime } from "../../../../component/formatter";
// import { Rating } from "@mui/material";
// import { Star } from "@mui/icons-material";


const UserFeedback = ({ user }) => {
  console.log(user)
  return (
    <div className="user_feedback my-5">
      <div className="user_feedback_header">
        <div className="d-flex justify-content-between">
          <div className="user_details">
            <div>
              <div className="user_img">
                <img src="/user.png" alt="" className="img-fluid" />
              </div>
            </div>

            <h2 className="user_name" style={{ fontSize: '15px' }}>{user?.talent ? user?.talent?.first_name + " " + user?.talent?.last_name :'Deleted Talent'}</h2>
            <div>
              <span className="tag">Talents</span>
            </div>
            {/* <p className="user_email">(jamesdoe@email.com)</p> */}
          </div>
          {/* <div className="delete_feedback">
            <img src="/delete.svg" alt="" className="img-fluid" />
          </div> */}
        </div>

        <div className="feedback_date d-flex gap-4">
          {formatDateTime(user?.createdAt)}
        </div>
      </div>
      <h3 className="feedback_subject my-4">Subject: {user?.reason}</h3>
      <h3 className="feedback_subject my-4">Type: {user?.type}</h3>
      <div className="user_feedback_body">
        <textarea name="" id="" cols="30" style={{ width: '100%', padding: '10px' }} rows="10" disabled value={user?.description} />
        {/* <div className="">
          <Rating
            precision={0.5}
            icon={<Star style={{ color: "#CC5600" }} />}
            emptyIcon={<Star style={{ color: "grey" }} />}
            defaultValue={user.rating}
            value={user.rating}
            readOnly
          />
        </div> */}
      </div>
    </div>
  );
};

export default UserFeedback;
