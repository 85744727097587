import React, { useState } from "react";
import Topbar from "../../components/topbar/Topbar";
import BreadCrumb from "@/component/bread-crumb/BreadCrumb";
import "../css/Update.css";
import InputField from "../../components/inputField/InputField";
import SelectField from "../../components/selectField/SelectField";
import Textarea from "../../components/textareaField/Textarea";
import { IconButton, Box } from "@mui/material";
import { DeleteOutline, EditOutlined } from "@mui/icons-material";
import Button from "@/component/buttons/Button";
import { Link } from "react-router-dom";

const CreateUpdate = () => {
  const [inputValue, setInputValue] = useState("");
  const notes = [
    {
      title: "Bug Fix: Login Page",
      text: "Identified and resolved a critical bug on the login page where users were unable to log in due to an issue with authentication tokens. Implemented a comprehensive solution to refresh tokens upon expiration, ensuring seamless user authentication. Additionally, conducted extensive testing to validate the fix across various devices and browsers to guarantee a smooth user experience under all conditions.",
    },
    {
      title: "Feature Plan: User Profile",
      text: "Outlined the detailed plan for implementing user profiles. It includes a wide range of features such as user information display, profile picture upload functionality with image cropping and resizing capabilities, account settings customization including password change and email preferences management, and integration with third-party services for social media linking. Additionally, the plan covers aspects like user profile privacy settings and GDPR compliance. The implementation strategy involves iterative development cycles with frequent user feedback loops to ensure that the final product meets user expectations and business requirements.",
      image:
        "https://img.freepik.com/free-photo/african-man-working-from-home_23-2149022791.jpg?t=st=1714057754~exp=1714061354~hmac=f20149fb8e5e84bfac3b7fa75aa6c98822999a8f627dc7ebb9d4ab6fc7085434&w=996",
    },
    {
      title: "Meeting Notes: Sprint Review",
      text: "Conducted a thorough review of the progress made during the current sprint. Discussed completed tasks, pending issues, and plans for the next sprint. Agreed upon prioritizing high-impact features and resolving critical bugs in the upcoming development cycle. Additionally, identified areas for improvement in the team's collaboration and communication processes. Discussed potential strategies for enhancing team productivity and morale, including team-building activities and knowledge-sharing sessions.",
      image:
        "https://img.freepik.com/free-photo/we-are-hiring-digital-collage_23-2149667031.jpg?t=st=1714058225~exp=1714061825~hmac=6f29381eb2a947635ad80b917e91114d20d49b35e4fef8f7d93aa1d1032a6d98&w=996",
    },
  ];

  const handleChange = (event) => {
    setInputValue(event.target.value);
  };

  const options = [
    { value: "option1", label: "Option 1" },
    { value: "option2", label: "Option 2" },
    { value: "option3", label: "Option 3" },
  ];
  const breadCrumbProp = [
    {
      title: "Back",
      link: "",
      previous: true,
    },
  ];
  return (
    <>
      <div className="col-lg-10  col-12 py-3">
        <div className="row">
          <div className="col-12">
            <Topbar name={"Updates"} />
          </div>

          <div className="col-12">
            <div
              className="table-container "
              style={{ borderTop: "1px solid #D4DAE2" }}
            >
              <BreadCrumb context={breadCrumbProp} />

              <div>
                <h2 className="update_title">Add New Newsletter</h2>
                <form className="row gap-3">
                  <div className="col-lg-5 my-2">
                    <InputField
                      value={inputValue}
                      placeholder={"Examinty Id"}
                      type={"text"}
                      helperText={"Add Release Note"}
                      title={"Title"}
                      onChange={handleChange}
                      isIconPresent={false}
                    />
                  </div>

                  <div className="col-lg-5 my-2">
                    <InputField
                      value={inputValue}
                      placeholder={"Examinty Id"}
                      type={"date"}
                      helperText={"Set Release Date"}
                      title={"Release Date"}
                      onChange={handleChange}
                      isIconPresent={false}
                    />
                  </div>
                  <div className="col-lg-5 my-2">
                    <Textarea
                      rows={8}
                      title={"Description"}
                      maxLength={500}
                      helperText={"Max. Character: 20"}
                      placeholder={"Enter Text Here"}
                    />
                  </div>
                  <div className="col-lg-5 my-2">
                    <SelectField
                      options={options}
                      title={"Post Type"}
                      helperText={"Select Post Type"}
                    />
                  </div>
                </form>
              </div>
              <div className="section_con">
                <h4 className="section_con_header my-2">Section Note</h4>
                <p className="_helper_text">Customize your Release note here</p>
                <>
                  {notes.map((note) => {
                    return (
                      <div className="notes my-5">
                        <div className="_notes_header">
                          <h2>{note.title} </h2>
                          <Box>
                            <IconButton
                              sx={{
                                color: "#172D2D",
                              }}
                            >
                              <EditOutlined />
                            </IconButton>
                            <IconButton
                              sx={{
                                color: "#BB363C",
                              }}
                            >
                              <DeleteOutline />
                            </IconButton>
                          </Box>
                        </div>

                        <div className="_notes_body">
                          <p className="my-3 text">
                            {note.text}
                            {note.image && (
                              <div className="note_image col-lg-8 my-3">
                                <img
                                  src={note.image}
                                  alt=""
                                  loading="lazy"
                                  width={"100%"}
                                />
                              </div>
                            )}
                          </p>

                          <div className="row">
                            <div className="col-lg-5">
                              <InputField
                                value={inputValue}
                                placeholder={"Examinty Id"}
                                type={"text"}
                                helperText={
                                  "This is the link to the Section created"
                                }
                                title={"Title"}
                                onChange={handleChange}
                                isIconPresent={true}
                                isReadOnly={true}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </>
                <div className="note_action">
                  <div className="d-flex gap-3">
                    <div>
                      <Button text="Discard" primaryNull={true} small={true} />
                    </div>
                    <div>
                      <Link to="/admin/previewupdate">
                        <Button
                          text="Create Update"
                          primaryNull={false}
                          small={true}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreateUpdate;
