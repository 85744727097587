import Button from "@/component/buttons/Button";
import "../css/Dashboardreview.css";
import Topbar from "../components/topbar/Topbar";
import { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import api from "@/services/api";
import Pagination from "../components/pagination/Pagination";
import Loading from "../components/loading/loading";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { formatDate } from "../../../component/formatter";
import { Block, Verified, NewReleases } from "@mui/icons-material";
import { baseUrl } from "../../../services/routes";
import { downloadFile } from "../components/downloadFile";
import InputField from "../components/inputField/InputField";
import { FormControl, MenuItem, Select } from "@mui/material";
import { Modal, Typography, Grid } from "@mui/material";

function Row({ row, verify, handelDelete, unverify, block, unblock }) {
  const navigate = useNavigate();
  const [isOptionOpen, setIsOptionOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [deletedId, setDeletedId] = useState(null);

  return (
    <Fragment className='position-relative'>
      <TableRow sx={{ "& > *": { border: "unset" } }} className="custom-row">
        <TableCell
          component="th"
          scope="row"
          className="custom-cell"
          style={{
            maxWidth: "100px",
          }}
        >
          <div className="user-image">
            <img
              src={
                row?.profile_picture?.href_link ||
                "https://upload.wikimedia.org/wikipedia/commons/thumb/5/59/User-avatar.svg/1024px-User-avatar.svg.png"
              }
              alt=""
            />
          </div>
        </TableCell>
        <TableCell className="custom-cell">
          {row.first_name + " " + row.last_name}
        </TableCell>
        <TableCell className="custom-cell">
          <span style={{ backgroundColor: '#FFB700', display: 'inline-block', padding: '5px', borderRadius: '10px', textAlign: 'center', color: 'white' }}>{row.full_time_decision}</span>
        </TableCell>
        <TableCell className="custom-cell">
          {formatDate(row.createdAt)}
        </TableCell>
        <TableCell className="custom-cell">
          <div className="d-flex gap-3 col-lg-3">
            <div>
              <Button
                text="Profile"
                primaryNull={true}
                small={true}
                onClick={() => navigate(`/admin/view-profile/${row._id}`)}
              />
            </div>
            <div>
              {row.isProfileApproved === false ? (
                <Button
                  text="Verify"
                  primaryNull={true}
                  small={true}
                  greenBtn={true}
                  onClick={() => verify(row?._id)}
                />
              ) : (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    justifyContent: "center",
                    gap: "5px",
                    textAlign: "center",
                    border: "1px solid green",
                    width: "100%",
                    padding: "6px 8px",
                    borderRadius: "5px",
                  }}
                >
                  <img
                    src="/img/svg/verified.svg"
                    alt=""
                    style={{ width: "24px", height: "24px" }}
                  />
                  <p style={{ margin: "0" }}>Verified</p>
                </div>
              )}
            </div>
            <div>
              <Button
                redBtn={false}
                text={<i className="fa-solid fa-trash"></i>}
                primaryNull={true}
                small={true}
                onClick={() => { setIsModalOpen(true); setDeletedId(row._id) }}
              />
            </div>
            <div style={{ position: "relative" }}>
              <button
                type="button"
                className="options"
                onClick={() => setIsOptionOpen(!isOptionOpen)}
              >
                <span>...</span>
              </button>
              {isOptionOpen && (
                <div className="action_box">
                  <button
                    type="button"
                    onClick={() =>
                      row.isProfileApproved === true
                        ? unverify(row._id)
                        : verify(row._id)
                    }
                    className="btn btn-primary mb-2"
                    style={{ width: "100px" }}
                  >
                    {row.isProfileApproved === true ? <NewReleases sx={{
                      width: '18px'
                    }} /> : <Verified sx={{
                      width: '18px'
                    }} />}

                    {row.isProfileApproved === true ? "Unverify" : "Verify"}
                  </button>


                  <button
                    type="button"
                    onClick={() =>
                      row.isBlocked === true ? unblock(row._id) : block(row._id)
                    }
                    className="btn btn-secondary mb-2 block "
                    style={{ width: "100px" }}
                  >
                    <Block sx={{
                      width: '18px'
                    }} />
                    {row.isBlocked === true ? "Unblock" : "Block"}
                  </button>
                </div>
              )}
            </div>
          </div>
        </TableCell>
      </TableRow>
      {
        isModalOpen && (
          <Modal
          open={isModalOpen}
          onClose={()=>setIsModalOpen(false)}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Grid
            container
            justifyContent="center"
            alignItems="center"
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              width: "80%",
              maxWidth: 400,
              bgcolor: "background.paper",
              boxShadow: 24,
              p: 4,
              borderRadius: 2,
            }}
          >
            <Grid item xs={12} textAlign="center">
              <Typography variant="h6" component="h2" gutterBottom>
                Warning
              </Typography>
            </Grid>
            <Grid item xs={12} textAlign="center" mb={2}>
              <img
                src="/img/Group 282.png"
                alt=""
                style={{ width: 'auto', height: 120 }}
              />
            </Grid>
            <Grid item xs={12} textAlign="center" mb={4}>
              <Typography variant="body1">
                You are about to delete a Talent.
              </Typography>
            </Grid>
            <Grid item container justifyContent="center" spacing={2}>
              <Grid item>
              <Button text="Cancel" primaryNull onClick={() => { setDeletedId(null); setIsModalOpen(false) }} />
              </Grid>
              <Grid item>
              <Button text="Continue" onClick={() => handelDelete(deletedId)} />
              </Grid>
            </Grid>
          </Grid>
        </Modal>
        )
      }

    </Fragment>
  );
}


const TalentDashoard = () => {
  const [filterOptions, setFilterOptions] = useState({
    talentName: "",
    status: '',
    startDate: "",
    endDate: "",
  });
  const [dateSortingOrder, setDateSortingOrder] = useState("asc");
  const [talents, setTalents] = useState([]);
  const [totalTalents, setTotalTalents] = useState("");
  const [totalVerifiedTalents, setTotalVerifiedTalents] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  // const [verifiedSortOrder, setVerifiedSortOrder] = useState("asc");
  const [filterType, setFilterType] = useState("");

  const handleSearch = (event) => {
    const searchTerm = event.target.value;
    setFilterOptions((prev) => ({
      ...prev,
      talentName: searchTerm,
    }));
  };


  const fetchData = () => {
    setLoading(true);
    const filters = {};
    if (filterOptions.talentName) {
      filters.talentName = filterOptions.talentName;
    }
    if (filterOptions.status) {
      filters.isProfileApproved = filterOptions.status;
    }
    if (filterOptions.startDate) {
      filters.startDate = filterOptions.startDate;
    }
    if (filterOptions.endDate) {
      filters.endDate = filterOptions.endDate;
    }
    api
      .getTalents({ ...filters, pageNumber: currentPage, pageSize: itemsPerPage })
      .then((res) => {
        setTalents(res.data.talents);

        setTotalTalents(res.data?.totalTalents);
        const verifiedTalents = res.data?.talents.filter(
          (talent) => talent.isProfileApproved === true
        );
        setTotalVerifiedTalents(verifiedTalents.length);

      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };
  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [currentPage, filterOptions,]);

  const verifyTalent = (id) => {
    setLoading(true);
    api
      .actionTalent(id, 'verify')
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          setLoading(false);
        }
      })
      .catch((res) => console.log(res));
  };
  const unverifyTalent = (id) => {
    setLoading(true);
    console.log(id);
    api
      .actionTalent(id, 'unverify')
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          setLoading(false);
        }
      })
      .catch((res) => console.log(res));
  };
  const blockTalent = (id) => {
    setLoading(true);
    api
      .actionTalent(id, 'block')
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          setLoading(false);
        }
      })
      .catch((res) => console.log(res));
  };
  const unblockTalent = (id) => {
    setLoading(true);
    api
      .actionTalent(id, 'unblock')
      .then((res) => {
        if (res.status === 200) {
          fetchData();
          setLoading(false);
        }
      })
      .catch((res) => console.log(res));
  };

  // const handleVerifiedSort = () => {
  //   // Toggle sorting order
  //   const newSortOrder = verifiedSortOrder === "asc" ? "desc" : "asc";
  //   setVerifiedSortOrder(newSortOrder);

  //   // Sort users array based on the isProfileApproved boolean
  //   const sortedUsers = [...talents].sort((a, b) => {
  //     const isApprovedA = a.isProfileApproved ? 1 : 0;
  //     const isApprovedB = b.isProfileApproved ? 1 : 0;

  //     if (newSortOrder === "asc") {
  //       return isApprovedA - isApprovedB;
  //     } else {
  //       return isApprovedB - isApprovedA;
  //     }
  //   });

  //   setTalents(sortedUsers);
  //   setIsOpen(false);
  // };

  const sortTalentsByName = () => {
    const sortedTalents = [...talents].sort((a, b) => {
      const nameA = `${a.first_name} ${a.last_name}`;
      const nameB = `${b.first_name} ${b.last_name}`;
      if (dateSortingOrder === 'asc') {
        return nameA.localeCompare(nameB);
      } else {
        return nameB.localeCompare(nameA);
      }
    });
    setTalents(sortedTalents);
  };


  const handleSortingChange = (e) => {
    setDateSortingOrder(e.target.value);
    sortTalentsByName();
  };

  const totalNumberOfPages = Math.ceil(totalTalents / itemsPerPage);

  const goToPreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const goToNextPage = () => {
    if (currentPage < totalNumberOfPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handelDelete = (id) => {
    setLoading(true)
    api
      .deleteTalent(id)
      .then(() => {
        setLoading(false)
        setTalents((prevTalent) => prevTalent.filter((user) => user._id !== id));
      })
      .catch((err) => console.log(err));
  };

  const exportTalents = () => {
    const url = baseUrl + '/talents/export'
    downloadFile(url, `talents.csv`)
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFilterOptions((prevOptions) => ({
      ...prevOptions,
      [name]: value,
    }));
  };

  return (
    <div className="col-lg-10  col-12 py-3">
      <div className="row gap-4">
        <div className="col-12">
          <Topbar
            name={"Talents"}
            briefInfo={"Welcome to the Frontters platform"}
            boxes={[
              {
                number: loading ? <Loading /> : `${totalTalents}`,
                label: "Talents",
                backgroundcolor: false,
              },
              {
                number: loading ? <Loading /> : `${totalVerifiedTalents}`,
                label: "Verifed",
                backgroundcolor: true,
              },
            ]}
          />
        </div>
        <div className="col-12">
          <div className="table-container">
            <div className="header_con mb-4 feedback-header py-3">
              <div className="row justify-content-between ">
                <div className="form d-lg-flex align-items-start gap-4 col-lg-10">
                  <div
                    className={`input-search ${filterType === "" && "col-lg-5"
                      } col-lg-4 d-flex py-2 mt-1`}
                  >
                    <div className="px-2">
                      <img src="/search.svg" alt="" />
                    </div>
                    <input
                      type="search"
                      placeholder="Search Talents"
                      className="input_text"
                      value={filterOptions.talentName}
                      onChange={handleSearch}
                    />
                  </div>
                  <div
                    className={`input-filter  ${filterType === "" && "col-lg-5"
                      } col-lg-3 d-flex pt-3`}
                  >
                    <select
                      className="input_text"
                      value={filterType}
                      onChange={(e) => setFilterType(e.target.value)}
                    >
                      <option selected value="">
                        Filter by
                      </option>
                      <option value="status">status</option>
                      <option value="date">date</option>
                    </select>
                  </div>
                  {filterType !== "" && (
                    <div className="col-lg-3 d-flex align-items-end py-2 my-4 my-lg-0">
                      {filterType === "status" && (
                        <FormControl>
                          <Select
                            name="status"
                            value={filterOptions.status}
                            onChange={handleChange}
                            displayEmpty
                            inputProps={{ "aria-label": "Without label" }}
                            className={`muiSelect ${filterOptions.status}`}
                          >
                            <MenuItem value={""}>Select Status</MenuItem>
                            <MenuItem value={true}>Verified</MenuItem>
                            <MenuItem value={false}>Not Verified</MenuItem>
                          </Select>
                        </FormControl>
                      )}
                      {filterType === "date" && (
                        <div className="row" style={{ marginTop: '-20px' }}>
                          <div
                            className="d-flex align-items-center gap-3"
                            style={{ width: "80%" }}
                          >
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"Start date"}
                                type={"date"}
                                value={filterOptions.startDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    startDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                            <div className="" style={{ width: "40%" }}>
                              <InputField
                                title={"End date"}
                                type={"date"}
                                value={filterOptions.endDate}
                                onChange={(e) => {
                                  setFilterOptions((prevOptions) => ({
                                    ...prevOptions,
                                    endDate: e.target.value,
                                  }));
                                }}
                              />
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  )}

                  <div className="d-flex gap-3 my-3 my-lg-0">
                    <div className="position-relative">
                      <Button
                        text="Sort"
                        primaryNull={true}
                        small={true}
                        onClick={() => setIsOpen(!isOpen)}
                      />
                      {isOpen && (
                        <div
                          className="position-absolute text-white p-2 rounded mt-2"
                          style={{
                            top: "100%",
                            left: "50%",
                            transform: "translateX(-50%)",
                            color: 'black'
                          }}
                        >
                          <div className="input-filter d-flex col-lg-3"
                            style={{ color: 'black', width: '180px' }}>
                            <div className="px-2 d-flex"
                              style={{ color: 'black' }}>
                              <i className="fa-regular fa-calendar-days"></i>
                              {dateSortingOrder === "asc" ? (
                                <i className="fa-solid fa-arrow-up"></i>
                              ) : (
                                <i className="fa-solid fa-arrow-down"></i>
                              )}
                            </div>
                            <select
                              className="form-select input_select"
                              aria-label="Default select example"
                              value={dateSortingOrder}
                              onChange={handleSortingChange}
                              key={dateSortingOrder}
                            >
                              <option value="asc">Ascending</option>
                              <option value="desc">Descending</option>
                            </select>
                          </div>
                        </div>
                      )}
                    </div>
                    <div>
                      <Button text="Export" primaryNull={false} small={true} onClick={exportTalents} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="header_con mb-4">
              <div className="form d-flex gap-3">
                <div className="input-search col-lg-4 d-flex">
                  <div className="px-2">
                    <i className="fa-solid fa-magnifying-glass"></i>
                  </div>
                  <input
                    type="search"
                    placeholder="Search Talentss"
                    value={filterOptions.talentName}
                    onChange={(e) =>
                      setFilterOptions((prev) => ({
                        ...prev,
                        talentName: e.target.value,
                      }))
                    }
                  />
                </div>
                <div className="input-filter d-flex col-lg-3">
                  <div className="px-2 d-flex">
                    <i className="fa-regular fa-calendar-days"></i>
                    {dateSortingOrder === "asc" ? (
                      <i className="fa-solid fa-arrow-up"></i>
                    ) : (
                      <i className="fa-solid fa-arrow-down"></i>
                    )}
                  </div>
                  <select
                    className="form-select input_select"
                    aria-label="Default select example"
                    value={dateSortingOrder}
                    onChange={handleSortingChange}
                    key={dateSortingOrder}
                  >
                    <option value="asc">Ascending</option>
                    <option value="desc">Descending</option>
                  </select>
                </div>
                <div className="d-flex gap-3">
                  <div className="position-relative">
                    <Button
                      text="Sort"
                      primaryNull={true}
                      small={true}
                      onClick={() => setIsOpen(!isOpen)}
                    />
                    {isOpen && (
                      <div
                        className="position-absolute text-white p-2 rounded mt-2"
                        style={{
                          top: "100%",
                          left: "50%",
                          transform: "translateX(-50%)",
                          minWidth: "180px",
                          backgroundColor: "#CC5600",
                        }}
                      >
                        <Button
                          text={"By Verification"}
                          primaryNull={false}
                          small={true}
                          onClick={handleVerifiedSort}
                        />
                      </div>
                    )}
                  </div>
                  <div>
                    <Button text="Export" primaryNull={false} small={true} onClick={exportTalents} />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="table-con table-responsive old_table ">
              {loading ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Loading />
                </div>
              ) : (
                <TableContainer>
                  <Table aria-label="JobListing table" sx={{ border: "none" }}>
                    <TableHead>
                      <TableRow className="custom-row">
                        <TableCell>
                          <div className="svg">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="21"
                              viewBox="0 0 20 21"
                              fill="none"
                            >
                              <path
                                d="M15.8327 4.06665H4.16602C3.50297 4.06665 2.86709 4.33004 2.39825 4.79888C1.92941 5.26772 1.66602 5.90361 1.66602 6.56665V14.9C1.66602 15.563 1.92941 16.1989 2.39825 16.6678C2.86709 17.1366 3.50297 17.4 4.16602 17.4H15.8327C16.4957 17.4 17.1316 17.1366 17.6004 16.6678C18.0693 16.1989 18.3327 15.563 18.3327 14.9V6.56665C18.3327 5.90361 18.0693 5.26772 17.6004 4.79888C17.1316 4.33004 16.4957 4.06665 15.8327 4.06665ZM4.16602 15.7333C3.945 15.7333 3.73304 15.6455 3.57676 15.4892C3.42048 15.333 3.33268 15.121 3.33268 14.9V12.8833L6.08268 10.1417C6.23846 9.98896 6.44789 9.90344 6.66602 9.90344C6.88414 9.90344 7.09357 9.98896 7.24935 10.1417L12.841 15.7333H4.16602ZM16.666 14.9C16.666 15.121 16.5782 15.333 16.4219 15.4892C16.2657 15.6455 16.0537 15.7333 15.8327 15.7333H15.191L12.016 12.5416L12.7493 11.8083C12.9051 11.6556 13.1146 11.5701 13.3327 11.5701C13.5508 11.5701 13.7602 11.6556 13.916 11.8083L16.666 14.55V14.9ZM16.666 12.2L15.0993 10.6417C14.6244 10.1853 13.9913 9.93051 13.3327 9.93051C12.6741 9.93051 12.041 10.1853 11.566 10.6417L10.8327 11.375L8.43268 8.97498C7.95773 8.51868 7.32465 8.26384 6.66602 8.26384C6.00739 8.26384 5.3743 8.51868 4.89935 8.97498L3.33268 10.5333V6.56665C3.33268 6.34564 3.42048 6.13368 3.57676 5.97739C3.73304 5.82111 3.945 5.73332 4.16602 5.73332H15.8327C16.0537 5.73332 16.2657 5.82111 16.4219 5.97739C16.5782 6.13368 16.666 6.34564 16.666 6.56665V12.2Z"
                                fill="#0B435F"
                              />
                            </svg>
                          </div>
                        </TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Date Joined</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {talents.map((telent, index) => {
                        return (
                          <Row
                            row={telent}
                            key={index}
                            verify={verifyTalent}
                            handelDelete={handelDelete}
                            unverify={unverifyTalent}
                            block={blockTalent}
                            unblock={unblockTalent}
                          />
                        );
                      })}
                    </TableBody>
                  </Table>
                  <Pagination
                    goToNextPage={goToNextPage}
                    goToPreviousPage={goToPreviousPage}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    totalNumberOfPages={totalNumberOfPages}
                  />
                </TableContainer>
              )}
            </div>
          </div>
        </div>
      </div>
    </div >
  );
};

export default TalentDashoard;
