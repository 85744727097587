import React from 'react';
import Modalheader from './Modalheader';
import Button from "@/component/buttons/Button";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const AlertModal = ({ openAlert, handleCloseAlert, handleDecline }) => {
  return (
    <Modal
      open={openAlert}
      onClose={handleCloseAlert}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Modalheader headText={'Warning'} handleClose={handleCloseAlert} />

        <div className="modal_body p-5">
          <div className="d-flex justify-content center align-items-center flex-column ">
            <h1 className="mb-3 alert_text">Warning</h1>
            <div className="warning m-3">
              <img src="/warning.png" alt="warning" />
            </div>
            <p className="alert_question">
              Are you certain you want to decline this job?
            </p>

            <div className="d-flex mt-4 gap-3">
              <Button
                text="No, Discard"
                primaryNull={true}
                small={true}
                onClick={handleCloseAlert}
              />
              <Button
                text="Yes, Decline"
                redBtn={true}
                small={true}
                onClick={handleDecline}
              />
            </div>
          </div>
        </div>
      </Box>
    </Modal>
  );
};

export default AlertModal;
