import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import "../modal.css";
import Button from "@/component/buttons/Button";
import Modalheader from "../../../dashboardreview/components/modal/Modalheader";
import Textarea from "../../../dashboardreview/components/textareaField/Textarea";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,
  bgcolor: "background.paper",
  border: "3.55px solid #000000",
  boxShadow: "10px 10px 0px 0px #000000",
};

const RestrictModal = ({
  open,
  handleClose,
 handleEnding,
  textValue,
  handleChange,
}) => {
  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Modalheader headText={"Admin Modal"} handleClose={handleClose} />
          <div className="modal_body p-2">
            <h4 className="modal_title my-3">Reasons for Ending the Conversation</h4>
            <div>
              <Textarea
                id="1"
                value={textValue}
                onChange={handleChange}
                rows={9}
                placeholder="Enter reasons for Ending the Conversation"
                maxLength={100}
              />
            </div>
            <div className="d-flex mt-4 gap-3">
              <Button
                text="Cancel"
                primaryNull={true}
                small={true}
                onClick={handleClose}
              />
              <Button
                text="End Conversation"
                primaryNull={false}
                small={true}
                onClick={handleEnding}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </div>
  );
};

export default RestrictModal;
