import React from "react";
import "./css/Topbar.css";
import Button from "@/component/buttons/Button";

const Topbar = ({
  name,
  briefInfo,
  boxes,
  className,
  isButton = false,
  onClick,
  isBox,
  BtnText
}) => {
  return (
    <div className="col-12">
      <div className={`title-review-con ${className}`}>
        <div className="row justify-content-between gx-5 gx-md-0 align-items-center ">
          <div className=" col-sm-4 col-12">
            <h3 className="username my-2">{name}</h3>
            <p className="brief-info">{briefInfo}</p>
          </div>

          {isBox && (
            <div className=" col-sm-8 col-12 d-flex gap-3 justify-content-end flex-md-nowrap flex-wrap">
              {boxes?.map((box, index) => (
                <div
                  className="box"
                  key={index}
                  style={{
                    backgroundColor: `${box.backgroundcolor ? "#CC5600" : ""}`,
                    border: `${box.backgroundcolor ? "none" : ""}`,
                  }}
                >
                  <h5
                    className="box-number"
                    style={{ color: `${box.backgroundcolor ? "#ffffff" : ""}` }}
                  >
                    {box.number}
                  </h5>
                  <span
                    style={{ color: `${box.backgroundcolor ? "#ffffff" : ""}` }}
                  >
                    {box.label}
                  </span>
                </div>
              ))}
            </div>
          )}

          {isButton && (
            <div className=" col-sm-4 col-12">
              <Button
                text={BtnText}
                primaryNull={true}
                small={true}
                onClick={onClick}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Topbar;
